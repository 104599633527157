import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Banner from "components/Banner/Banner";

function Partners() {
    const { type } = useParams();
    const [ section, setSection ] = useState();

    useEffect(() => {
        setSection(Sections.find(item => item.id.toLowerCase() === type.toLowerCase()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const crumbs = [
        { label: "Home", link :"/" },
        { label: "Chair" },
        { label: "Partners" }
    ]

    const phrases = [
        "The Chair is supported by a",
        "network of partnerships,",
        "ranging to both academia and civil society,",
        "and national and international institutions"
    ]

    return (
        <>
            {section &&
                <>
                    <Banner title={section.title} breadcrumbs={crumbs} phrases={phrases} />
                    <section id="partners" className="padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center heading_space animated wow fadeIn" data-wow-delay="300ms">
                                    <h2 className="heading bottom30 darkcolor font-light2 text-center">
                                        <span className="font-normal">{section.title}</span>
                                        <span className="divider-center"></span>
                                    </h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-10 offset-md-1 text-justify text-md-justify">
                                    <ul className="bottom35">
                                        {section.members.map((member, idx) => { return <li>{member}</li> })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }
        </>
    )
}

export default Partners;

const Sections = [
    { 
        id: "universities", title: "Universities, research centres and departments",
        members: [
            "CARISM (Centre d’Analyse et de Recherche Interdisciplinaires sur les Médias) of University of Paris II – Panthéon-Assas",
            "Centre of Distance Learning of the University of the State of Santa Catarina",
            "Centre for Humanities and Education of the University of the State of Santa Catarina",
            "Centre of Distance Learning of the University Eduardo Mondlane",
            "Centre of Philosophy and Human Sciences of the Federal University of Santa Catarina",
            "Centre of Languages, Literatures and Cultures of the University of Aveiro",
            "CÉSOR – École des Hautes Études en Sciences Sociales de Paris",
            "Chair of Audiovisual and Digital Teaching and Research, University of Paris II – Panthéon-Assas",
            "CHAM of the New University of Lisbon",
            "CJE of the University of São Paulo",
            "CLEPUL – Unit of University of Madeira",
            "Communication and Society Research Centre (CECS) of the University of Minho",
            "Critical Global Studies Institute at the Sogang University (Seoul, Korea)",
            "Department of Anthropology of the University of Massachusetts, Boston",
            "Department of Comparative Literature and Intercultural Studies at the University of Georgia",
            "Department of Environment and Planning at the University of Aveiro",
            "Department of Galician and Portuguese Philology of the University of Santiago de Compostela",
            "Department of History and Philosophy of the Faculty of Philosophy and Arts of the University of Alcalá de Henares",
            "Faculty of Arts and Humanities of the University of Beira Interior",
            "GRALL – Research Group for the Linguistics and Literary Analysis of Lusophony of the University of Santiago de Compostela",
            "GreenUPorto – Sustainable Agrifood Production Research Centre",
            "History Department of the University of Vechta",
            "IELT – Institute of Literature and Tradition Studies (Faculty of Human and Social Sciences –  Universidade Nova de Lisboa)",
            "International University Europa Prima (Macedonia)",
            "Institut Français de Presse",
            "Institute of Education of the University of Lisbon",
            "Institute of History of Law of The Faculty of Law of the University of Lisbon",
            "IRCL – University of Paul-Valéry Montpellier 3",
            "IST – Instituto Superior Técnico",
            "Lusófona University",
            "NEC – Federal University of Sergipe",
            "PRAXIS - Centre of Philosophy",
            "Polytechnic Institute of Tomar",
            "Polytechnic of Viseu",
            "Portuguese Catholic University – Braga",
            "Research Centre for Humanistic Studies of the University of Minho",
            "Rey Juan Carlos University of Madrid",
            "Rómulo – Ciência Viva of the University of Coimbra",
            "School of Management",
            "University Francisco de Vitoria",
            "University of Massey (New Zealand)",
            "University of Warwick",
            "University of Porto",
        ],
    },
    { 
        id: "public-institutions", title: "Public Institutions",
        members: [
            "City Council of Alenquer",
            "City Council of Famalicão",
            "City Council of Gouveia",
            "Economic and Food Safety Authority",
            "Parish of Ventosa",
            "Union of Parishes of Silvares, Pias, Nogueira e Alvarenga"
        ],
    },
    { 
        id: "unesco", title: "UNESCO Chairs",
        members: [
            "Archai UNESCO Chair",
            "UNESCO Chair of Ocean’s Cultural Heritage",
            "UNESCO – HUM.CILM chair",
            "UNESCO-UFMG «Térritorialités et Humanités: la Globalisation des Lumières»",
        ],
    },
    { 
        id: "foundations", title: "Foundations and Associations",
        members: [
            "ADEGI – Association for the Development of Global and Island Studies",
            "AICA – Atlantic Scientific Research Association",
            "Asociación de Escritores en Língua Galega",
            "Calouste Gulbenkian Foundation",
            "CECHAP – Centre for the Study of Culture, History, Art and Heritage",
            "CompaRes - International Society for Iberian-Slavonic Studies",
            "European Institute of Cultural Sciences P. Manuel Antunes",
            "Fernando Pessoa Institute",
            "Ibero-American Rhetoric Organization",
            "Observatory of Portuguese Language",
            "Realitas Institute",
            "Schloer Consulting Group",
            "Shrine of Our Lady of the Rosary of Fatima (Department of Studies)",
        ],
    },
    { 
        id: "graduate", title: "Graduate Programs",
        members: [
            "Master in European Medieval Studies: Images, Texts and Contexts, University of Santiago de Compostela",
            "Post-Graduation Program in Education of the University of the Sate of Santa Catarina",
            "Post-graduation Program in Linguistics and Portuguese Language – UNESP",
        ],
    },
]