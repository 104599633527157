import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Sections } from "./Research";
import Banner from "components/Banner/Banner";
import { GlobalHistories } from "./Internal/GlobalHistories";
import { GlobalMadeira } from "./Internal/GlobalMadeira";
import { MuseumSacredBook } from "./Internal/MuseumSacredBook";
import { PortugalGameOfMirrors } from "./Internal/PortugalGameOfMirrors";

function ResearchDetail() {
    const { id } = useParams();
    const [ section, setSection ] = useState();

    useEffect(() => {
        console.log("CHEGUEI VAZIO >> id", id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log("CHEGUEI >> id", id);
        setSection(Sections.find(item => item.id.toLowerCase() === id.toLowerCase()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const crumbs = [
        { label: "Home", link :"/" },
        { label: "Research" }
    ]
    
    return (
        <>
            {section &&
                <>
                    <Banner title={section.title} breadcrumbs={crumbs} />
                    <section id="research-detail" className="padding bglight">
                        <div className="container">
                            {id === "global-histories" && <GlobalHistories />}
                            {id === "portugal-game-of-mirrors" && <PortugalGameOfMirrors />}
                            {id === "museum-sacred-book" && <MuseumSacredBook />}
                            {id === "global-madeira" && <GlobalMadeira />}
                        </div>
                    </section>
                </>
            }
        </>
    )
}

export default ResearchDetail;