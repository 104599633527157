import React from "react";
import Banner from "components/Banner/Banner";

function Contacts() {
    const crumbs = [
        { label: "Home", link :"/" },
        { label: "Chair" }
    ]
    
    const phrases = [
        "The Chair takes advantage of the possibilities of",
        "distance learning in e-learning,",
        "applying the virtual pedagogical model",
        "in use at the Aberta University."
    ]
    
    return (
        <>
        <Banner title="Contacts" breadcrumbs={crumbs} phrases={phrases} />
            <section id="stayconnect1" className="bglight position-relative padding noshadow">
                <div className="container whitebox">
                    <div className="widget py-5">
                        <div className="row">
                            <div className="col-md-12 text-center wow fadeIn mt-n3" data-wow-delay="300ms">
                                <h2 className="heading bottom30 darkcolor font-light2 pt-1"><span className="font-normal">Contact</span> Us
                                    <span className="divider-center"></span>
                                </h2>
                                {/* <div className="col-md-8 offset-md-2 bottom35">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A dolores explicabo laudantium, omnis provident quam reiciendis voluptatum?</p>
                                </div> */}
                            </div>
                            <div className="col-md-6 col-sm-6 order-sm-2">
                                <div className="contact-meta px-2 text-center text-md-left">
                                    <div className="heading-title">
                                        <h2 className="darkcolor font-normal mb-4">Chair of <span className="defaultcolor">Global Studies</span></h2>
                                    </div>
                                    <p className="bottom10">Universidade Aberta</p>
                                    <p className="bottom10">Rua da Escola Politécnica 147, 1269-001 Lisboa, Portugal</p>
                                    <p className="bottom10">Tel.: (00351) 300 00 28 48</p>
                                    <p className="bottom10"><a className="d-block" href="mailto:catedraestudosglobais.ceg.uab@gmail.com">catedraestudosglobais.ceg.uab@gmail.com</a></p>
                                    <ul className="social-icons mt-4 mb-4 mb-sm-0 wow fadeInUp" data-wow-delay="300ms">
                                        <li><a href onClick={(e) => e.preventDefault()}><i className="fab fa-facebook-f"></i> </a> </li>
                                        <li><a href onClick={(e) => e.preventDefault()}><i className="fab fa-twitter"></i> </a> </li>
                                        <li><a href onClick={(e) => e.preventDefault()}><i className="fab fa-linkedin-in"></i> </a> </li>
                                        <li><a href onClick={(e) => e.preventDefault()}><i className="fab fa-instagram"></i> </a> </li>
                                        <li><a href onClick={(e) => e.preventDefault()}><i className="fab fa-whatsapp"></i> </a> </li>
                                        <li><a href onClick={(e) => e.preventDefault()}><i className="far fa-envelope"></i> </a> </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="heading-title  wow fadeInUp" data-wow-delay="300ms">
                                    <form className="getin_form wow fadeInUp" data-wow-delay="400ms" onSubmit={(e) => e.preventDefault()}>
                                        <div className="row px-2">
                                            <div className="col-md-12 col-sm-12" id="result1"></div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="name1" className="d-none"></label>
                                                    <input className="form-control" id="name1" type="text" placeholder="Name:" required  name="userName" />
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="email1" className="d-none"></label>
                                                    <input className="form-control" type="email" id="email1" placeholder="Email:" name="email" />
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="message1" className="d-none"></label>
                                                    <textarea className="form-control" id="message1" placeholder="Message:" required  name="message"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <button type="submit" id="submit_btn1" className="button gradient-btn w-100">Send</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="w-100">
                <div id="map" className="full-map"></div>
            </div>
        </>
    );
}

export default Contacts