import React, { useState, useEffect } from "react";
import { apiroutes } from "services/apiroutes/apiroutes";
import { authFetchAsync } from "services/auth/authFetch";
import Banner from "components/Banner/Banner";

function Presentation() {
    const crumbs = [
        { label: "Home", link :"/" },
        { label: "Chair" }
    ]
    
    // const [ data, setData ] = useState();
    // useEffect(() => { loadData(); }, []);
    
    // async function loadData() {
    //     let response = await authFetchAsync(`${apiroutes.contents}/1`, "GET", null, false);

    //     if (response.ok) {
    //         setData(await response.json());
    //     }
    // }

    const phrases = [
        "This Chair is the result of the intersection of",
        "four scientific areas",
        "Social Sciences, Environmental Sciences, Educational Sciences and Humanities,",
        "which represents an unprecedented and innovative institutional challenge"
    ]

    return (
        <>
            <Banner title="Presentation" breadcrumbs={crumbs} image="bg003.jpg" phrases={phrases} />
            <section id="presentation" className="padding_top padding_bottom">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6 padding_bottom_half">
                            {/* <div className="image"><img alt="SEO" src="/images/samples/aboutus.png" /></div> */}
                            <div className="image" style={{ backgroundColor: "white"}}><img alt="presentation" src="/images/presentation-bg.jpg" /></div>
                        </div>
                        <div className="col-lg-7 col-md-6 padding_bottom_half text-justify text-md-justify">
                            {/* <h2 className="darkcolor font-normal bottom30">Lets take your <span className="defaultcolor">Business</span> to Next Level</h2> */}
                            <p>Based in the relation between research and teaching, the Chair of Global Studies aims at the understanding the processes and dynamics of globalization, emphasizing approaches on the role of local, social and cultural diversities, namely those that are usually forgotten. Thus, the Chair dynamizes an emerging area of studies in Portugal and in Portuguese-speaking countries, taking as reference the “global studies” according to the recommendations of global institutions, namely UNESCO.</p>
                            <p>It aims to produce knowledge in an interdisciplinary perspective about the phenomena resulting from globalization, for the understanding and analysis of the interconnections between peoples and systems regarding economy, environment, culture and education. Promoting an advanced training to better prepare experts in dealing with the main themes, problems and alternatives of the globalization process, creating critical knowledge and capacity to intervene in the construction of an ecologically, socially and economically sustainable global society.</p>
                            <p className="bottom35">All teachers and researchers support the commitment to a Chair of Global Studies, where innovation is a central feature. Taking advantage of the possibilities of distance learning in e-learning, as well as from the experience and recognition of its researchers in fields of applied knowledge involved in this Chair, the Aberta University assumes de responsibility of answering the needs of advanced interdisciplinary training of researchers and professionals residing in national territory but also in Portuguese-speaking countries and Portuguese communities in foreign countries, aiming to contribute to a scientific community of Portuguese language around the world, with the creation of a network of interested parties in the field of teaching and research.</p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12 text-justify text-md-justify">
                            <h2 className="darkcolor font-normal bottom30">In this sense, the <span className="defaultcolor">Chair</span> has as its main objectives:</h2>
                            <ul className="bottom35">
                                <li>Analyse the phenomenon of globalization, concerning subjects related to political and social reality, intercultural communication, education towards globalization, environment, as well as the social, economic and language diversity of societies.</li>
                                <li>Critically reflect on global issues from an interdisciplinary and multidisciplinary perspective, specially the role of societies and people in this process.</li>
                                <li>Identify research needs by participating in research teams in Global Studies and disseminating research conducted with peers and the community in general.</li>
                                <li>Implement appropriate research methodologies and actions to solve global problems.</li>
                                <li>Develop interdisciplinary concepts, perspectives and discourses about global identity processes about culture, society, economy and environment;</li>
                                <li>Innovate towards the improvement of living conditions in the sustainable relation between culture, economy, society and the environment, from a global perspective.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Presentation;