import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Banner from "components/Banner/Banner";

function Team() {
    const { type } = useParams();
    const [ section, setSection ] = useState();

    useEffect(() => {
        setSection(Sections.find(item => item.id.toLowerCase() === type.toLowerCase()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const crumbs = [
        { label: "Home", link :"/" },
        { label: "Chair" },
        { label: "Team" }
    ]

    const phrases = [
        "All teachers and researchers support the commitment to",
        "a Chair of Global Studies,",
        "where innovation",
        "is a central feature."
    ]

    return (
        <>
            {section &&
                <>
                <Banner title={section.title} breadcrumbs={crumbs} phrases={phrases} />
                <section className="padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center heading_space animated wow fadeIn" data-wow-delay="300ms">
                                <h2 className="heading bottom30 darkcolor font-light2"> <span className="font-normal">{section.title}</span>
                                <span className="divider-center"></span>
                                </h2>
                                <div className="col-md-10 offset-md-1 text-justify">
                                    {section.body}
                                </div>
                            </div>
                        </div>
                        <div className="row equal-shadow-team">
                            {section.members.map((member, idx) => {
                                return (
                                    <div key={idx} className="col-lg-3 col-md-4 col-sm-6 col-12 pb-4">
                                        <div className="team-box wow fadeIn" data-wow-delay="300ms">
                                            <div className="image">
                                                <img src="/images/samples/team-1.jpg" alt=""/>
                                            </div>
                                            <div className="team-content">
                                                <h4 className="darkcolor">{member.name}</h4>
                                                <p>{member.title}</p>
                                                <p>{member.institution}</p>
                                                <ul className="social-icons-simple">
                                                    <li><a href onClick={(e) => e.preventDefault()}><i className="far fa-search"></i> </a> </li>
                                                    {/* <li><a className="facebook" href="javascript:void(0)"><i className="fab fa-facebook-f"></i> </a> </li> */}
                                                    {/* <li><a className="twitter" href="javascript:void(0)"><i className="fab fa-twitter"></i> </a> </li> */}
                                                    {/* <li><a className="insta" href="javascript:void(0)"><i className="fab fa-instagram"></i> </a> </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
                </>
            }
        </>
    )
}

export default Team;

const Sections = [
    { id: "board", title: "Board",
        body: <>
            <p>The Chair for Global Studies is fully supported by its host institution, the Aberta University, through its Rector and its other departments.</p>
            <p>The Aberta University has a structure of four main departments: the Department of Social Sciences and Management, the Department of Sciences and Technology, the Department of Humanities and the Department of Distance Learning and Education, all in strict articulation with the Rectory.</p>
            <p>The Chair for Global Studies has the direct support of the Rector, the Department of Sciences and Technology and Department of Social Sciences and Management, as well as the Scientific Council and the General Council, all of which provide institutional and material support for Chair activities.</p>
        </>,
        members: [
            { id: 1, name: "José Eduardo Franco", type: "Chair" },
            { id: 2, name: "Carla Oliveira" },
            { id: 3, name: "Cécile Méadel" },
            { id: 4, name: "Fabrice d’Almeida" },
            { id: 5, name: "João Luís Cardoso" },
            { id: 6, name: "José Ignacio Ruiz Rodríguez" },
            { id: 7, name: "Valérie Dévillard" },
            { id: 8, name: "Pierre-Antoine Fabre" }
        ]},
    { id: "pedagogical-committee", title: "Pedagogical Committee", members: [
        { id: 1, name: "João Relvão Caetano", type: "Coordinator" },
        { id: 2, name: "Célia Dias Ferreira" },
        { id: 3, name: "Cláudia Ribeiro de Almeida" },
        { id: 4, name: "Darlinda Moreira" },
        { id: 6, name: "Jeffrey Scott Childs" },
        { id: 7, name: "João Simão" },
        { id: 8, name: "Mário Filipe da Silva" },
        { id: 9, name: "Mário Negas" },
        { id: 10, name: "Paula Bacelar Nicolau" },
        { id: 11, name: "Rosa Sequeira" },
        { id: 12, name: "Sandra Caeiro" }
    ]},
    { id: "scientific-committee", title: "International Scientific Committee", members: [
        { id: 1, name: "Alberto López Rosado", institution: "University Francisco de Vitoria" },
        { id: 2, name: "Alejandra Vitale", institution: "University of Buenos Aires" },
        { id: 3, name: "Ana Pinto Moura", institution: "Aberta University" },
        { id: 4, name: "Annabela Rita", institution: "University of Lisbon" },
        { id: 5, name: "Arlindo Oliveira", institution: "University of Lisbon" },
        { id: 6, name: "Barbara Gori", institution: "University of Padova" },
        { id: 7, name: "Carlos Borrego", institution: "University of Aveiro" },
        { id: 8, name: "Carlos Carreto", institution: "New University of Lisbon" },
        { id: 9, name: "Carlos Fiolhais", institution: "University of Coimbra" },
        { id: 10, name: "Carlos Quiroga", institution: "University of Santiago de Compostela" },
        { id: 11, name: "Celso Carminati", institution: "University of the State of Santa Catarina" },
        { id: 12, name: "Christine Vogel", institution: "University of Vechta" },
        { id: 13, name: "Cristina Trindade", institution: "University of Madeira" },
        { id: 14, name: "Dionísio Vila Maior", institution: "Aberta University" },
        { id: 15, name: "Donald Huisingh", institution: "University of Tenesse" },
        { id: 16, name: "Edgard Leite Ferreira Neto", institution: "Federal University of Rio de Janeiro" },
        { id: 17, name: "Erroll G. Southers", institution: "University of Southern California" },
        { id: 18, name: "Eugénio Anacoreta Correia", institution: "Aberta University" },
        { id: 19, name: "Fátima Vieira", institution: "University of Porto" },
        { id: 20, name: "Fernando Moreira", institution: "University of Trás-os-Montes e Alto Douro" },
        { id: 21, name: "Filipa de Vasconcelos", institution: "ASAE" },
        { id: 22, name: "Francisco Gomez Mont Avalos", institution: "UNAM" },
        { id: 23, name: "Franck Salaün", institution: "University of Montpellier" },
        { id: 24, name: "Gabriele Cornelli", institution: "Federal University of Brasília/ UNESCO chair ARCHAI" },
        { id: 25, name: "Guilherme d’Oliveira Martins", institution: "Calouste Gulbenkian Foundation" },
        { id: 26, name: "Isabel Ruiz-Gallardon", institution: "Rey Juan Carlos University of Madrid" },
        { id: 27, name: "Jacinto Jardim", institution: "CIDH-Aberta University" },
        { id: 28, name: "Jacques Ehrenfreund", institution: "Université de Lausanne" },
        { id: 29, name: "Jean Pierre Chauvin", institution: "University of São Paulo" },
        { id: 30, name: "Jie-Hyun Lim", institution: "Sogang University" },
        { id: 31, name: "João Luís Monney de Sá Paiva", institution: "Polytechnic of Viseu" },
        { id: 32, name: "João Paulo Oliveria e Costa", institution: "New University os Lisbon" },
        { id: 33, name: "Joaquim Pintassilgo", institution: "Institute of Education of the University of Lisbon" },
        { id: 34, name: "Jordan Plevnes", institution: "International University EUROPA PRIMA –  Skopje" },
        { id: 35, name: "Jorge Trindade", institution: "Aberta University" },
        { id: 36, name: "José Carlos Lopes de Miranda", institution: "Portuguese Catholic University – Braga" },
        { id: 37, name: "José Maria da Silva Rosa", institution: "University of Beira Interior" },
        { id: 38, name: "José Porfírio", institution: "Aberta University" },
        { id: 39, name: "José Sales", institution: "Aberta University" },
        { id: 40, name: "Juan Antonio Melero Hernández", institution: "Rey Juan Carlos University of Madrid" },
        { id: 41, name: "Julio Seoane", institution: "University of Alcalá de Henares" },
        { id: 42, name: "Justino Magalhães", institution: "Institute of Education of the University of Lisbon" },
        { id: 43, name: "Lauro Moreira", institution: "Observatory of Portuguese Language" },
        { id: 44, name: "Lilian Jacoto", institution: "University of São Paulo" },
        { id: 45, name: "Luísa Paolinelli", institution: "University of Madeira" }, 
        { id: 46, name: "Luiz Carlos Villalta", institution: "Federal University of Minas Gerais/ UNESCO Chair «Térritorialités et Humanités: la Globalisation des Lumières»" },
        { id: 47, name: "Luiz Eduardo Oliveira", institution: "Federal University of Sergipe" },
        { id: 48, name: "Luiz Miguel Oosterbeek", institution: "Instituto Politécnico de Tomar/ Cátedra UNESCO HUM. CILM" },
        { id: 49, name: "Marcelo Lachat", institution: "Federal University of São Paulo" },
        { id: 50, name: "Marco Daniel Duarte", institution: "CIDH- Aberta University" },
        { id: 51, name: "Maria Alexandra Rodrigues Gonçalves", institution: "University of Algarve" },
        { id: 52, name: "Maria Isabel Morán Cabanas", institution: "University of Santiago de Compostela" },
        { id: 53, name: "Maria Manuel Baptista", institution: "University of Aveiro" },
        { id: 54, name: "Micaela Ramon", institution: "University of Minho" },
        { id: 55, name: "Miguel Taín Guzmán", institution: "University of Santiago de Compostela" },
        { id: 56, name: "Mihai I. Spariosu", institution: "University of Georgia at Athens" },
        { id: 57, name: "Moisés de Lemos Martins", institution: "University of Minho" },
        { id: 58, name: "Nildicéia Aparecida Rocha", institution: "University of the State of São Paulo" },
        { id: 59, name: "Nilsa A. I. E. Pondja Cherinda", institution: "University Eduardo Mondlane" },
        { id: 60, name: "Norberto Dallabrida", institution: "University of the State of Santa Catarina" },
        { id: 61, name: "Onésimo Teotónio Almeida", institution: "Brown University" },
        { id: 62, name: "Paulo Maria Bastos da Silva Dias", institution: "Aberta University" },
        { id: 63, name: "Paulo Mendes Pinto", institution: "Lusófona University" },
        { id: 64, name: "Pedro Caridade Freitas", institution: "University of Lisbon" },
        { id: 65, name: "R. Timothy Sieber", institution: "University of Massachusetts, Boston" },
        { id: 66, name: "Regina Zilberman", institution: "Federal University of Rio Grande do Sul" },
        { id: 67, name: "Riccardo Brizzi", institution: "University of Bologna" },
        { id: 68, name: "Rogério Souza", institution: "Federal University of Santa Catarina" },
        { id: 69, name: "Ruth Pereira", institution: "University of Porto" },
        { id: 70, name: "Stephen Gundle", institution: "University of Warwick" },
        { id: 71, name: "Tânia Martuscelli", institution: "University of Colorado in Boulder" },
        { id: 72, name: "Vania Pinheiro Chaves", institution: "CLEPUL" },
        { id: 73, name: "Viriato Soromenho-Marques", institution: "University of Lisbon" }
    ]},
    { id: "research-team", title: "Research Team", members: [
        { id: 1, name: "Aida Sampaio Lemos" },
        { id: 2, name: "Ana Catarina Mesquita" },
        { id: 3, name: "Ana Lúcia Curado" },
        { id: 4, name: "Ana Lúcia Tojal" },
        { id: 5, name: "Andreia Maria Mello" },
        { id: 6, name: "Bruno Venâncio" },
        { id: 7, name: "Carlos Augusto Calado Castanheira" },
        { id: 8, name: "Carlos Barradas " },
        { id: 9, name: "Carlos Filipe" },
        { id: 9, name: "Carlos Leone" },
        { id: 10, name: "Carmela Grüne" },
        { id: 11, name: "Cristiana Lucas Silva" },
        { id: 12, name: "Diogo Barbosa" },
        { id: 13, name: "Eduardo Soczek Mendes" },
        { id: 14, name: "Eugénia Magalhães" },
        { id: 15, name: "Fernando Marques" },
        { id: 16, name: "Fernanda Santos" },
        { id: 17, name: "Gislaine Valadares " },
        { id: 18, name: "Henrique Manuel Pereira" }, 
        { id: 19, name: "Isabel Drumond Braga" },
        { id: 20, name: "Isabel Nery" },
        { id: 21, name: "Jair de Almeida Júnior " },
        { id: 22, name: "Joana Balsa de Pinho" },
        { id: 23, name: "João Conduto" },
        { id: 24, name: "João Diogo Loureiro" },
        { id: 25, name: "João Lemos" },
        { id: 57, name: "Jorge Mangorrinha" },
        { id: 26, name: "José Bernardino" },
        { id: 27, name: "José Nilo Diniz" },
        { id: 28, name: "José Rodrigues dos Santos" },
        { id: 29, name: "Josineide Siqueira de Santana" },
        { id: 30, name: "Júlio Martín Fonseca" },
        { id: 31, name: "Lidice Ribeiro" },
        { id: 32, name: "Madalena Costa Lima" },
        { id: 33, name: "Manuel Curado" },
        { id: 34, name: "Marta Marecos Duarte" },
        { id: 35, name: "Milene Alves" },
        { id: 36, name: "Neto Ernesto Sequeira" },
        { id: 37, name: "Patrícia Leal" },
        { id: 38, name: "Patrícia Monteiro" },
        { id: 39, name: "Paula Carreira " },
        { id: 40, name: "Paulo Drumond Braga" }, 
        { id: 41, name: "Pedro Albuquerque" },
        { id: 55, name: "Pedro Caridade Freitas" },
        { id: 42, name: "Pedro Cegonho" },
        { id: 43, name: "Porfírio Pinto" },
        { id: 44, name: "Ricardo Ventura" },
        { id: 45, name: "Rita Balsa Pinho" },
        { id: 46, name: "Rui Carita" },
        { id: 47, name: "Rui Oliveira" },
        { id: 48, name: "Rui Pereira" },
        { id: 49, name: "Samuel Oliveira" },
        { id: 50, name: "Simão Daniel Silva" },
        { id: 51, name: "Sónia Vazão" },
        { id: 52, name: "Steffen Dix" },
        { id: 53, name: "Susana Alves-Jesus " },
        { id: 54, name: "Timóteo Cavaco" },
        { id: 55, name: "Tsunda Benoît-Serge" },
        { id: 56, name: "Vanda Figueiredo " }
    ]},
    { id: "advisory-committee", title: "Executive Advisory Committee", members: [
        { id: 1, name: "Cristiana Lucas Silva" },
        { id: 2, name: "José Bernardino" },
        { id: 3, name: "Milene Alves" },
        { id: 4, name: "Paula Carreira " },
        { id: 5, name: "Susana Alves-Jesus " }
    ]}
]