import React from "react";
// import { useSelector } from "react-redux";
import { Route as ReactRoute, Redirect } from "react-router-dom";

function Route(props) {    
    const { component: Component, render, rights = [] } = props;
    
    // const userRights = useSelector(state => state.userRights);
    const userRights = [];

    return (
        <ReactRoute path={props.path} render={props => {
            if (!hasAccess()) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: "/access"}} />
            }
            else {
                // authorized so return component
                return render ? render(props) : <Component {...props} />
            }
        }} />
    )

    function hasAccess() {
        var allowed = false;
        
        if (rights.length > 0) {
            rights.forEach(right => { 
                // eslint-disable-next-line no-prototype-builtins
                if(userRights.includes(right)) allowed = true
            })
        }
        // if no right was specified then user is allowed
        else allowed = true;

        return allowed;
    }
}

export default Route;