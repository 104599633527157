import React from "react";
import { Link } from "react-router-dom";
import { menuItems } from "../Header/Header";
import { news, sortItemsByDate } from "../../pages/News/News";

function Footer(props) {
    return (
        <footer id="site-footer" className=" bgdark padding_top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="footer_panel padding_bottom_half">
                            <a href="/" className="footer_logo bottom25"><img src="/images/C-UNESCO-logo-en-26-05-A.png" alt="MegaOne" /></a>
                            <a href="/" className="footer_logo bottom25"><img src="/images/logoUnescoAberta-inv.png" alt="MegaOne" /></a>
                            <div className="d-table w-100 address-item whitecolor bottom25">
                                <p className="d-table-cell align-middle bottom0">
                                    Chair of Global Studies<br/>
                                    Universidade Aberta<br/>
                                    Rua da Escola Politécnica 147, 1269-001 Lisboa, Portugal<br/>
                                    Tel.: (00351) 300 00 28 48<br/>
                                    <a className="d-block" href="mailto:catedraestudosglobais.ceg.uab@gmail.com">catedraestudosglobais.ceg.uab@gmail.com</a>
                                </p>
                            </div>
                            <ul className="social-icons white wow fadeInUp" data-wow-delay="300ms">
                                <li><a href onClick={(e) => e.preventDefault()} className="facebook"><i className="fab fa-facebook-f"></i> </a> </li>
                                <li><a href onClick={(e) => e.preventDefault()} className="twitter"><i className="fab fa-twitter"></i> </a> </li>
                                <li><a href onClick={(e) => e.preventDefault()} className="linkedin"><i className="fab fa-linkedin-in"></i> </a> </li>
                                <li><a href onClick={(e) => e.preventDefault()} className="insta"><i className="fab fa-instagram"></i> </a> </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="footer_panel padding_bottom_half bottom20 pl-0 pl-lg-5">
                            <ul className="links">
                                {menuItems.filter(item => !item.hideInFooter).map((item, idx) => {
                                    return <li key={idx} className="nav-item">
                                        <a href={`#${item.link}`}>{item.label}</a>
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="footer_panel padding_bottom_half bottom20">
                            <h3 className="whitecolor bottom25">Latest News</h3>
                            {news.length === 0 ?
                                <span className="no-news">Nothing to tell about.. for now!</span>
                            :
                                <ul className="latest_news whitecolor">
                                    {sortItemsByDate().slice(0, 5).map((item, idx) => {
                                        return (
                                            <li>
                                                <Link to={`/news/${item.id}`}>{item.title}</Link>
                                                <span className="date defaultcolor">{item.date.toDateString()}</span>
                                            </li>)
                                    })}
                                </ul>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;