import React from "react";
import { Link } from "react-router-dom";
import { Sections } from "../Research";

export const GlobalMadeira = () => {
    let section = { 
        id: "global-madeira",
        title: "Global Madeira",
        description: <>
            <p>The 21st century will increasingly be the century of knowledge, seen as a goal and priority for the progress of peoples. Knowledge is the new name of development. Countries and regions will be distinguished above all by culture and science, as decisive capital for generating innovation and new sources of wealth. We cannot forget that the period of our history in which Portugal stood out among other nations for the voyages of discovering the maritime paths was based on the accumulation, intelligent management and the appropriate application of scientific and technical knowledge that allowed our country to create the first empire of modernity and become the pioneer, that is, the father of globalization in which we fully live today.</p>
            <p>This knowledge, held by the Order of Christ, direct heir to the Templars, and by an entrepreneurial Jewish elite, stimulated by the religious ideal of universalization of Christianity, allowed our country to write one of the golden pages in the macro-history of humanity, and to realize the gnosiological feat of making “the world be known by the world itself”, in Vieira's words, and creating the first global database on peoples, cultures and civilizations on the planet.</p>
            <p>This global revolution of perspectives, which put an end to the myths and the fantastic imagery of the regional vision of the world of the Middle Ages, allowed to develop in Europe the ideal of a new universality, which the encyclopedists of the Century of Lights would translate in the construction of a global encyclopedic knowledge, starting from France and England. The idea of a critical systematization of an exhaustive, comprehensive and multifocal knowledge of the reality that can be known represented a moment of progress, which later made it possible to make advances on various levels of human knowledge.</p>
            <p>That project, important for humanity as a whole, was later adapted to the exhaustive and multi-encompassing knowledge of specific nations and territories. The production and publication of this encyclopedic knowledge ended up generating, mutatis mutandis, a series of milestones, which inaugurated new stages in the cultural and scientific history of peoples, countries and regions.</p>
            <p>The Madeira archipelago had that first milestone almost 100 years ago, with the preparation of the Elucidário Madeirense, on the 500th anniversary of its official discovery by the Portuguese. Now the archipelago has the even more extraordinary opportunity to affirm itself with another milestone that will be the publication of the Great Encyclopedic Dictionary of Madeira, elaborated after 5 years of intense work in which more than 500 researchers and authors were involved, linked to 30 scientific areas and subareas.</p>
            <p>Initially, the project applied for European funds by APCA with the support of the Government of the Autonomous Region of Madeira, for an output of 4 to 5 volumes. Today, thanks to a fidelity to scientific ethics that led the team to cover in this systematization of knowing everything that was relevant in terms of knowledge of our autonomous region, the workload almost quintupled in relation to the initial project. According to the most recent data survey, the content written and the 10% of the texts remaining to be completed will amount to about 35,617,500 characters.</p>
            <p>The Great Encyclopedic Dictionary will have thousands of entries and sub-entries, and more than 15,000 pages on subjects ranging from Marine Biology and Geology to Astronomy, Philosophy and Theology; that is, critical knowledge is being produced on topics that reach from the bottom of the sea and the Earth to the highest of Heavens.</p>
            <p>In parallel, products that were not initially planned are being prepared, with special emphasis on a children’s version of the Great Encyclopedic Dictionary, seeking to sow the knowledge that will flourish in the future generations. At the same time, translation teams were prepared and publishers were found in order to publish this knowledge in two languages of great international circulation and considered strategic for Madeira: English and Spanish.</p>
            <p>For its part, this great scientific operation, involving researchers from different generations and different fields of knowledge, is generating new projects and new ideas around it, as if, by metaphorical analogy, it was a river crossing a desert and creating oases of culture on its banks. The A to Z Theater Conferences and the Andarilhas Conferences are good examples of these new projects thanks to an excellent initiative by two coordinators of the Encyclopedic Dictionary, Luísa Paolinelli and Cristina Trindade, in partnership with the Director of the Municipal Theater Baltazar Dias.</p>
        </>,
        objectives: [
            "To constitute a repository of several and distinct collections of sacred texts.",
            "Research the tangible and intangible heritage included in its collections.",
            "Publicly expose each legacy by showing how, from the narratives expressed, each community thinks and celebrates the divine.",
            "Construct a questioning grid that contributes to a pedagogy and curriculum of the different, as well as to the democratization of the presence of religions in the public space and promotion of tolerance."
        ]
    };

    return (
        <>
            <div className="row whitebox top15">
                <div className="col-lg-8 col-md-7">
                    <div className="widget heading_space text-justify pb-0 mb-6">
                        <div className="row">
                            <p className="bottom30">{section.description}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-5">
                    <aside className="sidebar whitebox mt-5 mt-md-0">
                            <div id="accordion" className="heading_space shadow wow fadeIn bottom30" data-wow-delay="350ms">
                                <div className="card">
                                    <div className="card-header">
                                        <a className="card-link darkcolor" data-toggle="collapse" href="#collapseOne">Published volumes:</a>
                                    </div>
                                    <div id="collapseOne" className="collapse" data-parent="#accordion">
                                        <div className="card-body">
                                            <h2 className="darkcolor font-normal bottom10"><span className="defaultcolor">Madeira Global</span> Grande Dicionário Enciclopédico da Madeira, Volume 1, Lisbon, Theya Editores, 2019</h2>
                                            <p className="mb-0"><span className="label"><i>Directed by:</i></span> José Eduardo Franco</p>
                                            <p className="mb-0"><span className="label"><i>Executive Coordination:</i></span> Cristina Trindade</p>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <h3 className="darkcolor font-normal top10">Synopsis</h3>
                                                    <p className="text-justify bottom30">In this first volume, we can find the entries between “Abacateiro” and “Atividades marítimo-turísticas”. According to the idea of the project, the entries in this Great Dictionary cover about 30 scientific areas and sub-areas, with the participation of a true national and international network of more than 500 authors and researchers.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div className="widget heading_space shadow wow fadeIn" data-wow-delay="350ms">
                            <h4 className="text-capitalize darkcolor bottom20 text-center text-md-left">Other Researches</h4>
                            {Sections.filter(item => item.id !== section.id).map((item, idx) => {
                                return (<div key={idx} className="single_post bottom15">
                                    <Link to={`/research/${item.id}`}>
                                        <i className="fas fa-books"></i>
                                    </Link>
                                    <div className="text">
                                        <Link to={`/research/${item.id}`}>{item.title}</Link>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </aside>
                </div>
            </div>
        </>
    )
}