import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import AppWrapper from "./AppWrapper";
import history from "services/init/history";
import registerServiceWorker from "./serviceWorker";
import store from "./services/store/store";
import "layout/sass/ceg.scss";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const renderApp = () => {
	ReactDOM.render(
    <Provider store={store}>
        <HashRouter history={history}>
          <AppWrapper />
        </HashRouter>
    </Provider>,
	document.getElementById("root")
	);
	
  registerServiceWorker();
}

renderApp();