import React from "react";
import Banner from "components/Banner/Banner";
import { Link } from "react-router-dom";

function Education() {
    const crumbs = [
        { label: "Home", link :"/" }
    ]

    return (
        <>
            <Banner title="Education and Training" breadcrumbs={crumbs} />
            <section id="our-services" className="padding bglight">
                <div className="container">
                    <div id="services-measonry" className="cbp">
                        {Sections.map((section, idx) => {
                            return (
                                <div key={idx} className="cbp-item digital brand design">
                                    <div className="services-main">
                                        <div className="image bottom10">
                                                {section.image ?
                                                    <img alt={section.imageAlt} src={`/images/logos/${section.image}_560x320.png`}/>
                                                :
                                                    <div className="no-image">
                                                        <i className="fas fa-graduation-cap"></i>
                                                    </div>
                                                }
                                            <div className="overlay">
                                                <a href={`education/${section.id}`} className="overlay_center border_radius"><i className="fa fa-eye"></i></a>
                                            </div>
                                        </div>
                                        <div className="services-content brand text-center text-md-left">
                                            <h3 className="bottom10 darkcolor"><a href={`education/${section.id}`}>{section.title}</a></h3>
                                            {section.description && <p className="bottom15">{section.description}</p>}
                                            <a href={`education/${section.id}`} className="button-readmore">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Education

export const Sections = [
    { 
        id: "global-studies",
        title: "Doctoral Program for Global Studies",
        image: "doutoramento-estudos-globais",
        home: "Articulating, in an interdisciplinary perspective, knowledge from the Social Sciences, Humanities, Environment and Education, the DPGS promotes advanced training that prepares professionals to deal with the major themes, problems and alternative ways associated with the globalization process, creating critical knowledge and ability to intervene in the context of building a sustainable eco-socio-culturally global society.",
        description: "Doctoral Program in partnership with the University Paris II Panthéon Assas, Department of Information and Communication Sciences, and with CéSor-EHESS-Paris",
        body: <>
            <p>Based on the relation between research and teaching, the Doctoral Program for Global Studies (DPGS) has as its object the world in dynamic globalization. The nature, intensity and speed of the interconnections between peoples, economies, cultures and the environment raise opportunities, but also problems and risks to our coexistence, demanding new cognitive and operational tools.</p>
            <p>Articulating, in an interdisciplinary perspective, knowledge from the Social Sciences, Humanities, Environment and Education, the DPGS promotes advanced training that prepares professionals to deal with the major themes, problems and alternative ways associated with the globalization process, creating critical knowledge and ability to intervene in the context of building a sustainable eco-socio-culturally global society.</p>
            <p>The main aim of the Program is to provide students with the skills to understand and solve problems from an international and global perspective. This is a multifaceted and non-hegemonic Program in global contexts that sees societies as subsystems of an interdependent global world in view of social justice. Students will be able to assume the direction and management of research, teaching in an autonomous way, as well as developing actions in specific global contexts, using digital tools in their organizations and produce theoretical and practical knowledge adequate to society and national and international academia.</p>
            <p>A doctorate in Global Studies should be able to lead experimental/research projects, to conceive research and intervention projects in this area, carrying out change processes that strongly consider the use of platforms and networks, considering the domains of intervention in organizations, in general, but also in society, next to groups and individuals, in particular.</p>
            <p>Students should be able to have a complex thinking that, instead of overlapping, articulates knowledge and re-approximates theory and practice in a transdisciplinary approach.</p>
            <p>The Program here presented adopts an approach that intendeds to be balanced with regard to its scientific areas, comparable only in part with other 2-3 doctoral courses currently offered in the European area. From its comparison with the other programs, the Doctoral Program in Global Studies is pertinent because it combines social sciences with environment and humanities, surpassing the connection between Global Studies to economy. The Program proposes the deepening in three main areas – Paradigms of Globalization; Sustainability, Environment and Globalization; Economic Globalization and Social Protests – optionally complemented with three other more reflexive areas – Culture, Education and Plurilingualism.</p>
            <p>By being based on an online Distance Learning model, this Doctoral Program is unprecedented at an European and international level, meeting the necessities of the labour market and of people who seek adequate training for the resolution of global problems.</p>
            <p>The collaboration of several external experts in related areas is planned, selected from national and international universities, within the framework of the Forum in Global Studies, whose contribution will enrich the Program with knowledge and experiences from multiple contexts, as well as facilitate international dissemination of the Program. The Program will also have a close collaboration with CIDH and CEMRI and the various research centres located in the UAb, as well as other research centres where its teachers are affiliated. UAb’s 14 Local Learning Centres (LLC) throughout Portugal and in Mozambique will provide additional equipment (exhibition/intervention rooms, auditoriums, Internet access, etc.) to be made available for the Program activities. </p>
            <p>The creation of this Program intends to contribute to open horizons to new transdisciplinary knowledge and skills, fully assuming the risk of the underlying innovation process.</p>
        </>,
        objectives: {
            title: <>By completing the Program, the <span className="defaultcolor">student</span> should be able to:</>,
            items: [
                "Analyse the phenomenon of globalization, concerning subjects related to political and social reality, intercultural communication, education towards globalization, environment, as well as the social, economic and language diversity of societies.",
                "Critically reflect on global issues from an interdisciplinary and multidisciplinary perspective, specially the role of societies and people in this process.",
                "Identify research needs by participating in research teams in Global Studies and disseminating research conducted with peers and the community in general.",
                "Implement appropriate research methodologies and actions to solve global problems.",
                "Develop interdisciplinary concepts, perspectives and discourses about global identity processes about culture, society, economy and environment.",
                "Innovate towards the improvement of living conditions in the sustainable relation between culture, economy, society and the environment, from a global perspective."
            ]
        },
        link: "https://www2.uab.pt/guiainformativo/detailcursos.php?curso=103"
    },
    { 
        id: "entrepreneurship-citizenship",
        title: "Education for Entrepreneurship and Citizenship",
        image: "empreende",
        home: "This innovative program combines research, intervention and evaluation, as a way to guarantee the social impact of its actions. In this sense, it is based on theoretical models and reflective and empirically validated teaching and learning strategies. These models include cognitive-behavioral models, differentiated pedagogy, cooperative learning, positive psychology and a humanistic approach.",
        body: <>
            <p>The objective of this program is to continue and deepen the work started by the Office of Education for Entrepreneurship and Citizenship, within the scope of the Chair Infante Dom Henrique for Atlantic Island Studies and Globalization, of the Aberta University (CIDH-UAb). In this sense, Education for Entrepreneurship and Citizenship has as its main mission the promotion of entrepreneurial culture through the development of the skills, emotions, values and tools of that culture. It has a markedly transdisciplinary team of researchers, teachers and trainers, assisted by national and international collaborators, developing research projects and intervention programs in an interdisciplinary approach in order to contribute to people’s self-realization, the success of organizations, social cohesion and economic growth.</p>
            <p>Created and developed by Jacinto Jardim, with the institutional supervision of José Eduardo Franco, this innovative program combines research, intervention and evaluation, as a way to guarantee the social impact of its actions. In this sense, it is based on theoretical models and reflective and empirically validated teaching and learning strategies. These models include cognitive-behavioral models, differentiated pedagogy, cooperative learning, positive psychology and a humanistic approach. On the other hand, it favors strategies for building and implementing programs, as well as holding academic and training events on topics related to the sciences of entrepreneurship.</p>
        </>,
        objectives: {
            items: [
                "Promote and develop the skills, emotions, values and tools of the entrepreneurial culture in education and training.",
                "Research, intervene and evaluate in order to change behaviors, models and practices of education for entrepreneurship and citizenship.",
                "Build and validate pedagogical instruments on soft skills.",
                "Develop, implement and evaluate entrepreneurship education programs for all human development cycles, from childhood to old age.",
                "Optimize synergies between all collaborators who are part of the national and international education network for entrepreneurship."
            ]
        },
    },
    { 
        id: "international-seminars",
        title: "International Seminars on Global Studies",
        image: "seminario-estudos-globais",
        home: "In order to think about globalization in different scientific and cultural perspectives, these seminars occur monthly and already have more than two dozen sessions.",
        body: <>
            <p>Started in 2016, the Permanent Seminars on Global Studies were the result of an initiative organized in partnership with the Imprensa Nacional-Casa da Moeda and the Aberta University, through the Chair Infante Dom Henrique for Atlantic Island Studies and Globalization (CIDH-UAb-CLEPUL).</p>
            <p>From 2019, these seminars became a partnership between the CIDH-UAb-CLEPUL and the Calouste Gulbenkian Foundation, and were renamed International Seminars on Global Studies.</p>
            <p>In order to think about globalization in different scientific and cultural perspectives, these seminars occur monthly and already have more than two dozen sessions.</p>
        </>
    },
    { 
        id: "lunchtime-seminars",
        title: "Lunchtime Seminars",
        image: "seminario-hora-almoco",
        home: "These seminars are intended to be a space for regular debate, where junior researchers and other guests present the results of their research.",
        body: <>
            <p>These seminars are intended to be a space for regular debate, where junior researchers and other guests present the results of their research.</p>
            <p>These moments work with two periods, of 30 minutes each, for a maximum total of one hour, between 1 pm and 2 pm. With this initiative, a dynamic and informal space was created, in which each speaker per session will be able to present ongoing research and submit it to an audience, who, in the same period of time, will respond with questions and comments, thus promoting dialogue and exchange of experiences. This type of debate was inaugurated in 2012 and has already had several presentations.</p>
        </>
    },
    { 
        id: "pombaline-studies",
        title: "Advanced Seminar on Pombaline Studies",
        image: "seminario-estudos-pombalinos",
        home: "The main objective of this initiative is to provide scientific training within the scope of Pombaline Studies, with the participation of national and international researchers, in an interdisciplinary, interinstitutional and intergenerational perspective.",
        body: <>
            <p>These seminars are intended to be a space for regular debate, where junior researchers and other guests present the results of their research.</p>
            <p>These moments work with two periods, of 30 minutes each, for a maximum total of one hour, between 1 pm and 2 pm. With this initiative, a dynamic and informal space was created, in which each speaker per session will be able to present ongoing research and submit it to an audience, who, in the same period of time, will respond with questions and comments, thus promoting dialogue and exchange of experiences. This type of debate was inaugurated in 2012 and has already had several presentations.</p>
        </>
    }
]