import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import App from "./App";

const AppWrapper = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [props.location]);

    return (
        <App />
    );
}

export default withRouter(AppWrapper);