import React from "react";
import { Link } from "react-router-dom";
import { Sections } from "../Research";

export const PortugalGameOfMirrors = () => {
    let section = {
        id: "portugal-game-of-mirrors",
        title: "Portugal in a Game of Mirrors",
        subtitle: "The map of our affections and disaffections",
        description: <>
            <p>By knowing, signaling and understanding the discourses of others about us, and of us about others, we can offer an interesting map of affections and disaffections, a kind of cultural GPS of stereotypes that helps us to better be situated and be guided in time and history, particularly in the “frequent negotiations” that we have to engage in order to establish relations of partnership or antipathy.</p>
            <p>The representations of a country, an institution, an individual, are part of its history, its cultural heritage. The more or less stereotyped representations that we build about others, and that others elaborate about us, function as a game of mirrors, generating deformed images. But these disfigured perspectives are the eyes with which we see and interpret reality.</p>
            <p>Thus, the exercise of mapping our affections and disaffections, on which our view of the other is often based, is fundamental for the understanding of historical articulations and to shed light on the relations of the present and the challenges of the future.</p>
            <p>Therefore, this collection now inaugurated was born from an ambitious project, but realistically accomplished in incremental steps, to meet the challenge of thinking Portugal and to contribute to the critical reflection on the identity of this country, mostly built in its relationship with other peoples, established during its almost one millennium of history. It is, in effect, about providing a true game of mirrors, accepting to observe Portugal from the perspective inscribed in the cultural discourses of other countries and, within our country, of the different regions.</p>
        </>,
        board: [
            "José Eduardo Franco",
            "Fátima Vieira"
        ],
        synopsis: <>
            <p>Starting from the basic question of “how does the Other see me?” – or, in other words, “how is Portugal seen by the Other?” –, the collection seeks answers to another key question – “what are the underlying motives for such a perspective?”</p>
            <p>Since Maurice Halbwachs, we have been aware of how collective memory works. We know that it is, above all, a social construction, which is the result of different versions of the past, which results from the interaction and communication between individuals, depending, to a great extent, on the places of memory, as Pierre Nora would come to call them, those cultural, social or mental material phenomena that societies associate with the past and with national identity, and that fulfill a mnemonic function. Secondly, we know that this memory is relational and social, that all of our memories are inscribed in socio-cultural contexts, and that, for this reason, has been contaminated, since the beginning, by the social groups to which we belong and that continuously process, select and hierarchize events, showing processes of similarity or rupture. Finally, we know that cultural memory is a discursive construction, which is always constructed in reference to a present whose legitimacy the social groups intend to confirm or put into question, and that the moments of silence – the gaps in the discourse that correspond to forgetfulness – deserve extra attention.</p>
            <p>This collection systematically explores representations of Portugal – discourses about Portugal – in an exercise of reflection on what, from our past, is remembered by other nations or different Portuguese regions, and how it is remembered. Informed by the awareness that places of memory are dynamic entities, capable of being continually updated in their symbolic investment according to the interests of the present, the collection invests in a critical analysis of the discourses about Portugal that, over time, have been juxtaposed and overlapping, dictating the relations of affections and disaffections that it intends to map out.</p>
        </>
    }

    return (
        <>
            <div className="row whitebox top15">
                <div className="col-lg-8 col-md-7">
                    <div className="widget heading_space text-justify">
                        <div className="row">
                            <p className="bottom30">{section.description}</p>
                        </div>
                        <div className="row">
                            <h3 className="darkcolor font-normal bottom30">Cultural memory</h3>
                            <p className="bottom30">{section.synopsis}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-5">
                    <aside className="sidebar whitebox mt-5 mt-md-0">
                        <div className="widget heading_space text-justify">
                            {/* <h3 className="darkcolor font-normal bottom30">{section.title}</h3> */}
                            {/* <p className="bottom30">{section.description}</p> */}
                            <div id="service-detail">
                                <div className="bottom30">
                                    <h4>Directed by:</h4>
                                    <ul>{section.board.map((director, idx) => { return <li key={idx}>{director}</li>})}</ul>
                                </div>
                            </div>
                        </div>
                        <div className="widget heading_space shadow wow fadeIn" data-wow-delay="350ms">
                            <h4 className="text-capitalize darkcolor bottom20 text-center text-md-left">Other Researches</h4>
                            {Sections.filter(item => item.id !== section.id).map((item, idx) => {
                                return (<div key ={idx} className="single_post bottom15">
                                    <Link to={`/research/${item.id}`}>
                                        <i className="fas fa-books"></i>
                                    </Link>
                                    <div className="text">
                                        <Link to={`/research/${item.id}`}>{item.title}</Link>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </aside>
                </div>
            </div>
        </>
    )
}