import React from "react";
import { Link } from "react-router-dom";
import { Sections } from "../Research";

export const GlobalHistories = () => {
    let section = { 
        id: "global-histories",
        title: "Global Histories",
        description: "This project intends to provide critical knowledge through an exercise of analytical synthesis that would allow an overview of the history that, derived from Portuguese territory, or having taken place in this territory, influenced the process of consensus and differences of the current global world. This broad research project includes the Global History of Portuguese Literature, the Global Encyclopedia of Portugal, the Global History of Portugal.",
        board: [
            "Carlos Fiolhais",
            "José Eduardo Franco",
            "José Pedro Paiva"
        ],
        coordination: [
            "João Luís Cardoso (Prehistory and Proto-History)",
            "Carlos Fabião (Antiquity)",
            "Bernardo Vasconcelos and Sousa (Middle Ages)",
            "Cátia Antunes (Modern Era)",
            "António Costa Pinto (Contemporary Era)"
        ],
        synopsis: <>
            <p>We intend to contribute with a reference project to affirm Portuguese history and
            cultural heritages with an innovative project, in line with what has been done internationally in
            countries like France, Italy, Spain, the USA, Germany and the Netherlands: the Global History
            of Portugal, followed by global Portuguese sectoral histories.</p>
            <p>It is, therefore, following this ongoing project and its theoretical framework that we
            intend to promote a project entitled Global Encyclopaedia of Portugal and Global Portuguese
            Histories, in which several researchers coming from Portuguese and foreign universities may
            participate and produce new syntheses on the history of Portugal, based on the reading keys
            of the so-called global history, whose fundamental idea is explained below.</p>
            <p>The 21st century has put on the agenda throughout all cultural areas reflection on the
            globalization phenomenon, which affects us in the most diverse sectors and forces us to
            review the way we learn to build knowledge about people, countries and the planet we
            inhabit.</p>
            <p>One of the fields in which this transformation begins to be visible and rehearsed is in
            the way of looking at states and nations’ pasts. Through our old schoolbooks, we learn to know
            the history of our country, as was done everywhere else, from a national point of view, taking
            as our starting point the territorial configuration that comprised the state, the nation and the
            various elements that made up that territoriality, circumscribed on the physical, symbolic,
            cultural, mental, spiritual... From this angle of vision, the nation was studied first, and the rest
            of the planet was looked at from the level where the national gallery was, from whose window
            the whole world was observed and known.</p>
            <p>According to this construction of history, which has been in force practically until
            today, each nation assumed itself as the navel of the world, and the world became a
            necessarily secondary landscape, with which relationships of cooperation, transaction,
            influence, domination, conflict, separation, negation or, in some cases, welcoming were
            established. History was known in a bipolar, dualistic way: there was the us and the others.
            And the others could be the friendly peoples or the enemy peoples.</p>
            <p>In this sense, the surrounding landscape was known from a national perspective,
            considering only its own values, its stages, its goals, achieved or frustrated. Often, &quot;national
            history&quot; was taught to us as a reality that was autonomous from world history, detached from
            it, with an independent existence, and could, for simple, ideologically conditioned
            explanations, be explained as if nothing else existed, or, if it did exist, as if its existence were
            not decisive.</p>
            <p>This new historiographical ideology cannot be confused with that of comparative
            history, although it draws some benefits from it. In fact, in order to understand the dynamics
            that gave rise to global interconnections and interactions, it is essential to bear in mind that, in
            this field, the historian&#39;s work does not consist, as Sebastian Conrad writes, in &quot;writing a total

            history of our planet&quot;, even when exploring a particular movement, religious, cultural, political,
            among others. We can only aim to have &quot;in mind the global connections and structural
            conditions&quot;, so as to try to understand some long-lasting dominants and their changes in hinge
            moments, which blossom after great maturity. In fact, as the quoted scholar reminds us,
            &quot;global history, therefore, is not synonymous with &#39;macro-history&#39;. Often in global history the
            most interesting problems arise at the point of intersection between global processes and
            their local manifestations.&quot;<sup><a href="#1" onClick={(e) => navigateToAnchors(e)}>1</a></sup>.</p>
            <p>Along these lines, a sharp reflection on global history has begun to emerge in several
            countries, implying the need to review our construction of history from a national reasoning.
            This epistemological reflection is giving rise in the historiographical field to the new global
            histories. Thus, in recent years, world histories of France and Italy have come to light, and
            histories with global perspectives have already been published in the United States.
            In the framework of this epistemological ideology, countries are no longer considered
            as spaces closed by a horizon configured under a national perspective, from where one starts
            building its history, but as a territorial platform taken in the very extensive duration of the
            humanisation process, from the first human communities, which left their mark in terms of
            culture and memory, to the most recent migratory waves, which go beyond borders and
            disregard the limits that previously compartmentalised humanity in national spaces.
            Nation-countries are a form of organisation that has only existed for about 500 years.
            But the territories thus circumscribed form the stage on which peoples, ethnic groups, clans,
            tribes, lords, kingdoms and empires have passed through and acted over the millennia,
            circulating and settling without regard for current borders (with a stability of a few centuries, if
            we count history in terms of millennia).</p>
            <p>The area that today is called Portugal, which has one of the oldest stable borders in
            Europe (with about eight centuries), was, like all countries, crossed for thousands of years by
            the dynamic circulation of different ethnic groups and peoples, who, since the primitive
            prehistoric globalisation, as defined by Edgar Morin in his work Penser Global, circulated
            through the continents in a phase of geological stabilisation, following the climatic rhythms
            and cycles. The territorial rectangle that is currently called Portugal was a point of arrival and
            departure of peoples, cultures and languages, settling and moving in territorial designs,
            physical and identity, variable.</p>
            <p>We thus intend to produce critical knowledge decentred from a national perspective,
            from which we have been used to for knowing about our past. With these projects' results, we
            will see much of the world in Portugal and much of Portugal in the world, as we will try to
            identify and understand transversalities and how much these helped in the construction of a
            country that today is the one with which we identify ourselves as a people and as a nation,
            which is the result of multiple crossovers.</p>
            <p>Our projects for preparing Global Portuguese Histories, followed by a Global
            Encyclopedia of Portugal, intend to offer - counting with the participation of specialists and
            researchers in the various historical periods and with contributions from other areas of
            knowledge, in interdisciplinary work - a critical knowledge, through the exercise of analytical
            synthesis, It is a meeting place for other worlds and various movements (of influence and
            inter-influence, of shocks and fruitful exchanges), which contributed relevant material to the
            understanding of the global history of our planet.</p>
            <br/>
            <hr/>
            <div id="anchors" className="bottom30">
                <span id="anchor-1"><sup>1</sup>Sebastian Conrad, Historia Global: Una Nueva Visión para el Mundo Actual, Barcelona, Crítica, 2017, p.16.</span>
            </div>
        </>
    }

    const navigateToAnchors = (ev) => {
        ev.preventDefault();
        let element = document.getElementById("anchors");
        element && element.scrollIntoView({ behavior: "smooth", block: "end"});
    }

    return (
        <>
            <div className="row whitebox top15">
                <div className="col-lg-8 col-md-7">
                    <div className="widget heading_space text-justify">
                        <div className="row">
                            <p className="bottom30">{section.description}</p>
                        </div>
                        <div className="row">
                            <h3 className="darkcolor font-normal bottom30">Synopsis</h3>
                            <p>{section.synopsis}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-5">
                    <aside className="sidebar whitebox mt-5 mt-md-0">
                        <div className="widget heading_space text-justify">
                            {/* <h3 className="darkcolor font-normal bottom30">{section.title}</h3> */}
                            {/* <p className="bottom30">{section.description}</p> */}
                            <div id="service-detail">
                                <div className="bottom30">
                                    <h4>Directed by:</h4>
                                    <ul>{section.board.map((director, idx) => { return <li>{director}</li>})}</ul>
                                </div>
                                <div className="bottom30">
                                    <h4>Scientific Coordination:</h4>
                                    <ul>{section.coordination.map((coordinator, idx) => { return <li>{coordinator}</li>})}</ul>
                                </div>
                            </div>
                        </div>
                        <div className="widget heading_space shadow wow fadeIn" data-wow-delay="350ms">
                            <h4 className="text-capitalize darkcolor bottom20 text-center text-md-left">Other Researches</h4>
                            {Sections.filter(item => item.id !== section.id).map((item, idx) => {
                                return (<div key ={idx} className="single_post bottom15">
                                    <Link to={`/research/${item.id}`}>
                                        <i className="fas fa-books"></i>
                                    </Link>
                                    <div className="text">
                                        <Link to={`/research/${item.id}`}>{item.title}</Link>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </aside>
                </div>
            </div>
        </>
    )
}