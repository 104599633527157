import React from "react";
import { Link } from "react-router-dom";
import { Sections } from "../Research";

export const MuseumSacredBook = () => {
    let section = { 
        id: "museum-sacred-book",
        title: "International Museum of the Sacred Book",
        description: <>
            <p>The discovery of writing was an extraordinary development in the long course of human civilization. Writing was developed as a technique and as art in order to define the sacred and to “translate” the transcendent. The great religions that characterize the great civilizations and the great human nations have been founded by a written work or writings that were later were collected in the so-called sacred books. “Sacred writing,” the founder of culture and civilization, is umbilically linked to the history of art, for writing was considered a particular art. Therefore, sacred writing, besides the expression of writing itself, was usually accompanied by processes of artistic intervention that provided its distinction and elevation and also functioned as a way of compensating the high levels of illiteracy of society.</p>
            <p>Sacred writing, which originated sacral culture, must be understood and framed within the coordinates of space and time. Space, in parallel with writing, is a founding element of the definition and phenomenal expression of the sacred. It is by the discourse of the sacred held in a sacred place that the sacred is circumscribed, revealed or affirmed as a means of consolidating the distinction of the human from the natural environment as a whole.</p>
            <p>Sacred writing presents itself as the means of communication par excellence between the divine sphere and the human sphere. The sacred texts are par excellence a crucial instrument as a vehicle for both thinking about the divine and the beliefs and practices of celebration adopted over time and for the most diverse civilizations. Interpreting the sacred books as a cultural product, whose cosmologies regarding the divine and the human comprise collective memories and historical knowledge, sacred writing could be seen as a tangible heritage of an intangible faith.</p>
            <p>Thus, it is urgent to recognize two important aspects: first, that sacred writing is a means of producing and disseminating multicultural representations of God, of man and of the world; second, in their didactic function, religious narratives operate as discourses that guide the daily life of the individual and of communities of believers. The scriptures that act in sacred time and space are therefore an indispensable instrument in the diffusion and fixation of cultural and historical memories and in the production of knowledge regarding the way each religious otherness sees each other. From this perspective, the texts that serve as a source and support for the religious experience are presented either as historical documents or as a result of multiple and distinct knowledges and cultural experiences.</p>
            <p>Considering the museum space as the guardian of collective memory and promoter of historical knowledge, as a place for a person’s full development and for the promotion of a responsible citizenship, the main purpose of the International Museum of the Sacred Book is to contribute for the preservation, understanding and dissemination of texts conveyed in the context of religious knowledge and practices.</p>
        </>,
        objectives: [
            "To constitute a repository of several and distinct collections of sacred texts.",
            "Research the tangible and intangible heritage included in its collections.",
            "Publicly expose each legacy by showing how, from the narratives expressed, each community thinks and celebrates the divine.",
            "Construct a questioning grid that contributes to a pedagogy and curriculum of the different, as well as to the democratization of the presence of religions in the public space and promotion of tolerance."
        ]
    }

    return (
        <>
            <div className="row whitebox top15">
                <div className="col-lg-8 col-md-7">
                    <div className="widget heading_space text-justify">
                        <div className="row">
                            <p className="bottom30">{section.description}</p>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12 col-md-12 text-justify text-md-justify">
                                <h2 className="darkcolor font-normal bottom30">Following this design, its <span className="defaultcolor">objectives</span> are:</h2>
                                <ul className="bottom35">{section.objectives.map((obj, idx) => { return <li key={idx}>{obj}</li>})}</ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-5">
                    <aside className="sidebar whitebox mt-5 mt-md-0">
                        <div className="widget heading_space shadow wow fadeIn" data-wow-delay="350ms">
                            <h4 className="text-capitalize darkcolor bottom20 text-center text-md-left">Other Researches</h4>
                            {Sections.filter(item => item.id !== section.id).map((item, idx) => {
                                return (<div key={idx} className="single_post bottom15">
                                    <Link to={`/research/${item.id}`}>
                                        <i className="fas fa-books"></i>
                                    </Link>
                                    <div className="text">
                                        <Link to={`/research/${item.id}`}>{item.title}</Link>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </aside>
                </div>
            </div>
        </>
    )
}