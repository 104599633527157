import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { sortItems, news } from "./News";
import Banner from "components/Banner/Banner";

function Article() {
    const { id } = useParams();
    const crumbs = [
        { label: "Home", link :"/" },
        { label: "News", link :"/news" }
    ]
    
    const [ section, setSection ] = useState();

    useEffect(() => {
        setSection(news.find(item => item.id === parseInt(id, 10)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);


    return (
        <> {section &&
            <>
                <Banner title={section.title} breadcrumbs={crumbs} />
                <section id="our-blog" className="bglight padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-7">
                                <div className="news_item shadow">
                                    {section.images && section.images.length > 0 &&
                                        <div className="image">
                                            {section.images.map((image, idx) => {
                                                return <img key={idx} src={`/images/news/${image}`} alt="Latest News" className="img-responsive"/>
                                            })}
                                        </div>}
                                    <div className="news_desc text-center text-md-left">
                                        <h3 className="text-capitalize font-normal darkcolor">{section.subtitle}</h3>
                                        <ul className="meta-tags top20 bottom20">
                                            <li><a href="#."><i className="fas fa-calendar-alt"></i>{section.date.toDateString()}</a></li>
                                            {section.attachment &&
                                                <li><a href={`/downloads/${section.attachment}`}><i className="fas fa-paperclip"></i> Anexo</a></li>}
                                        </ul>
                                        <p className="bottom35">{section.body}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <aside className="sidebar whitebox mt-5 mt-md-0">
                                    {/* <div className="widget shadow heading_space text-center text-md-left">
                                        <h4 className="text-capitalize darkcolor bottom20">search By Keyword</h4>
                                        <form className="widget_search">
                                            <div className="input-group">
                                                <label for="searchInput" className="d-none"></label>
                                                <input type="search" className="form-control" placeholder="search..." required id="searchInput"/>
                                                <button type="submit" className="input-group-addon"><i className="fa fa-search"></i> </button>
                                            </div>
                                        </form>
                                    </div> */}
                                <div className="widget heading_space shadow wow fadeIn" data-wow-delay="350ms">
                                    <h4 className="text-capitalize darkcolor bottom20 text-center text-md-left">Recent News</h4>
                                        {sortItems().filter(item => item.id !== section.id).map((item, idx) => {
                                            return (<div key ={idx} className="single_post bottom15">
                                                <Link to={`/news/${item.id}`}>
                                                    <i className="fas fa-books"></i>
                                                </Link>
                                                <div className="text">
                                                    <Link to={`/news/${item.id}`}>{item.title}</Link>
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        }
        </>
    );
}

export default Article