import React from "react";
import Banner from "components/Banner/Banner";
import { Link } from "react-router-dom";

function Published() {
    const crumbs = [
        { label: "Home", link :"/" }
    ]

    return (
        <>
            <Banner title="Published" breadcrumbs={crumbs} />
            <section id="our-services" className="padding bglight">
                <div className="container">
                    <div id="services-measonry" className="cbp">
                        {Sections.map((section, idx) => {
                            return (
                                <div className="cbp-item digital brand design">
                                    <div className="services-main">
                                        <div className="image bottom10">
                                            <div className="image">
                                                {section.image ?
                                                    <img alt={section.imageAlt} src={`/images/logos/${section.image}_560x320.png`}/>
                                                :
                                                    <div className="no-image">
                                                        <i className="fas fa-graduation-cap"></i>
                                                    </div>
                                                }
                                            </div>
                                            <div className="overlay">
                                                <Link to={`/published/${section.id}`} className="overlay_center border_radius"><i className="fa fa-eye"></i></Link>
                                            </div>
                                        </div>
                                        <div className="services-content brand text-center text-md-left">
                                            <h3 className="bottom10 darkcolor"><Link to={`/published/${section.id}`}>{section.title}</Link></h3>
                                            {section.description && <p className="bottom15">{section.description}</p>}
                                            <Link to={`/published/${section.id}`} className="button-readmore">Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Published

export const Sections = [
    { 
        id: "global-studies",
        title: "Global Studies Collection",
        image: "colecao-estudos-globais",
        description: "Understanding Globalization - Global Studies Collection",
        board: ["Guilherme d’Oliveira Martins", "João Relvão Caetano", "José Eduardo Franco"],
        body: <>
            <p>Global Studies, now undergoing a significant expansion, are building an epistemological field that will allow circumscribing an emerging disciplinary area that we may call globalogy. Globalogy is, by general definition, a science that seeks to understand the world in the process of globalization, in its history and in the complexity of its present state, characterized by movements of exchanges, interactions between human communities spread throughout the planet and that generated and continue to generate interrelationships, interconnections and metamorphoses in the most diverse levels (cultural, economic, political, religious, social, etc.). This globalizing movement, due to multiple ways of mobility (both physical and virtual) now facilitated and accelerated by the exponential development of information and transportation technologies, has raised an increasingly acute awareness that the human being is an integrated part in a world that has a planetary dimension and needs to be (and can be) better understood if we realize a vast totality sensitive to its interactions, starting from its locality in the world. This means that our understanding of the world will always be “glocal”, that is, based on the reality where we were born and where we formed and modeled our identity. Basically, the global is a composite reality, integrating the diverse locations and their interactions.</p>
            <p>Global Studies have the purpose of globally studying the metamorphic relationship with the local and vice versa. It is a science since it aims to understand, but can be associated with technique, since its effort of understanding can be interconnected with the need to establish objectives in order to create intervention instruments to improve what we can call the global field in its various problematic dimensions and in the relationship between the human activity and the environment, whether natural or social.</p>
            <p>In the context of these and other debates, in the framework of different theoretical perspectives and narratives that propose different hermeneutical approaches for understanding the challenges and that guide the search for solutions to the dilemmas of the current world, Global Studies is a privileged interdisciplinary research field, which aims not only to take part in the debate and to find tools to understand a global world, but also to contribute to the construction of a developed and harmonious world, through the qualification of relations between peoples and cultures based on values of justice, equanimity, fraternity, pluralism, respect for difference and promotion of a sustainable harmonic relationship between human beings and the natural environment.</p>
            <p>The editorial project Understanding Globalization – Collection of Global Studies aims to create a qualified editorial space to promote the dissemination of critical knowledge of themes and problems in the perspective of Global Studies, namely studies produced in Portuguese language.</p>
        </>
    },
    { 
        id: "religo-global",
        title: "Religo Global Collection",
        image: "colecao-religo-global",
        description: <>
            <p>Religion, Politics, Culture and Globalization</p>
            <p>CIDH-Aberta University / CéSor-EHESS / CR-Lusophone University / IECCPMA / Theya Editora</p>
        </>,
        board: ["José Eduardo Franco", "Paulo Mendes Pinto", "Pierre Antoine Fabre"],
        body: <>
            <p>The RELIGO GLOBAL editorial collection promotes the edition of monographs, academic theses, critically annotated editions of sources and individual and collective works of study on themes related to the religious universe in their relation to the political and cultural field, in order to contribute to knowledge about protagonists, movements, institutions and problems associated with the process of building globalization in the Modern and Contemporary Period.</p>
        </>
    },
    { 
        id: "brazil-collection",
        title: "Brazil Collection",
        description: "Lisboa / São Paulo",
        board: ["Vania Pinheiro Chaves", "Tânia Regina De Luca"],
        body: <>
            <p>The Brazil Collection was initially published in electronic format by the Chair Infante Dom Henrique for Atlantic Island Studies and Globalization, related to the Centre for Lusophone and European Literatures and Cultures of the Faculty of Letters of the University of Lisbon (CLEPUL) – in partnership with Fundação Editora UNESP (FEU). This fundamental work will now be continued within the scope of the Chair of Global Studies.</p>
            <p>Therefore, this collection provides a privileged place to founding works of Brazilian literature, including disperse texts by Portuguese-Brazilian writers and historical and literary documents in manuscript form that remain difficult to find and read, and whose publication in the 17th or 18th centuries, in particular in periodicals and collective works, has long been erased from the cultural memory of Brazil. Publishing these writings of great historical and/or aesthetic value in ebook, with updated spelling, introductory study and explanatory notes will make them accessible to a multitude of readers from all over the world.</p>
            <p>Among the first volumes of the collection we can find, besides the first work – Descrição da Quinta de Belas, by Domingos Caldas Barbosa, practically forgotten even among scholars from Brazil and Portugal after its princeps edition, dated 1799 –, three collections of the poetry of José Basílio da Gama, among which the Latin poem Brasilienses Aurifodinae stands out, until now unpublished and untranslated. The lyrical poetry by this important Arcadian from Minas, who belonged to the Roman Arcadia, glorified the Pombaline administration and resided in Portugal most of his life, will include unknown compositions or versions different from those previously published. Also noteworthy is the volume of satires authored by Olavo Bilac under pseudonyms, published in periodicals in which he collaborated, although, according to the testimony by Eloy Pontes about his will, he forbade their republishing.</p>
            <p>The collection will publish, on the other hand, essays resulting from recent research in areas of knowledge not yet explored, carried out by researchers dedicated to the study of the culture, literature and history of Brazil, including the reconstruction and analysis of a fundamental period of Jorge Amado’s life and political militancy, when he was in exile in France and Czechoslovakia, and in which he maintained a close connection with foreign intellectuals and artists with a similar ideological stance, by the Portuguese-Canadian researcher Rui Afonso, and the study of the reception of Lima Barreto in Portugal, carried out by João Marques Lopes, as a fellow of the Fundação Biblioteca Nacional, in Rio de Janeiro.</p>
            <p>These are just the first proposals of a series that seeks to be extremely fruitful in disseminating relevant works and documents from and about Brazil. Directed by Professors Vania Pinheiro Chaves (University of Lisbon) and Tânia Regina De Luca (UNESP), the Brazil Collection includes a Scientific Committee formed by experts in the different fields of knowledge it covers. This international committee brings together professors and researchers from several Portuguese, Brazilian and foreign institutions, whose main function is the evaluation of the works to be published. The competence, exemption and number of these advisors does not prevent them from publishing in the Brazil Collection, as they are experts in the subjects they evaluate, with the appreciation of the work of one of them being carried out by other members of the said committee.</p>
        </>
    }
]