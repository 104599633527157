import React from "react";
import classNames from "classnames";

function Header() {
    
    return (
        <>
            <header className="site-header" id="header">
                <nav className={classNames("navbar navbar-expand-lg", window.location.pathname === "/" ? "transparent-bg" : "padding-nav", "static-nav")}>
                    <div className="container">
                        <a className="navbar-brand" href="/">
                            <img src="/images/logo-white.png" alt="logo" className="logo-default" />
                            <img src="/images/logo.png" alt="logo" className="logo-scrolled" />
                        </a>
                        <div className="collapse navbar-collapse">
                            <ul className="navbar-nav mx-auto">
                                {menuItems.map((item, idx) => {
                                    if (item.items) {
                                        return (
                                            <li key={idx} className="nav-item dropdown static">
                                                <a className="nav-link dropdown-toggle active" href onClick={(e) => e.preventDefault()} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{item.label}</a>
                                                <ul className="dropdown-menu megamenu">
                                                    <li>
                                                        <div className="container">
                                                            <div className="row">
                                                                {item.items.map((child, childIdx) => {
                                                                    if (child.items) {
                                                                        return <div key={`${idx}-${childIdx}`} className="col-lg-4 col-md-6 col-sm-12">
                                                                            <h5 className="dropdown-title bottom10">{child.label}</h5>
                                                                            {child.items.map((childItem, childItemIdx) => {
                                                                                return <a key={`${idx}-${childIdx}-${childItemIdx}`} href={`#${childItem.link}`} className="dropdown-item">{childItem.label}</a>
                                                                            })}
                                                                        </div>
                                                                    }
                                                                    else {
                                                                        return <div key={`#${idx}-${childIdx}`} className="col-lg-4 col-md-6 col-sm-12">
                                                                            {child.isExternal ? 
                                                                                <a href={child.link} className="dropdown-item" rel="noopener noreferrer" target="_blank">{child.label}</a>
                                                                            :
                                                                                <a href={`#${child.link}`} className="dropdown-item">{child.label}</a>
                                                                            }
                                                                        </div>
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                        )
                                    }
                                    else {
                                        return <li key={idx} className="nav-item">
                                            <a href={`#${item.link}`} className="nav-link">{item.label}</a>
                                        </li>
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                    <a href onClick={(e) => e.preventDefault()} className="d-inline-block d-lg-none sidemenu_btn" id="sidemenu_toggle">
                        <span></span> <span></span> <span></span>
                    </a>
                </nav>
                {/* side menu */}
                <div className="side-menu opacity-0">
                    <div className="overlay"></div>
                    <div className="inner-wrapper">
                        <span className="btn-close btn-close-no-padding" id="btn_sideNavClose"><i></i><i></i></span>
                        <nav className="side-nav w-100">
                            <ul className="navbar-nav">
                                {menuItems.map((item, idx) => {
                                    if (item.items) {
                                        return (
                                            <li key={idx} className="nav-item">
                                                <a className="nav-link collapsePagesSideMenu" data-toggle="collapse" href={`${idx}`}>{item.label} <i className="fas fa-chevron-down"></i></a>
                                                <div id={idx} className="collapse sideNavPages">
                                                    <ul className="navbar-nav mt-2">
                                                        {item.items.map((child, childIdx) => {
                                                            if (child.items) {
                                                                return <li key={childIdx} className="nav-item">
                                                                    <a className="nav-link collapsePagesSideMenu" data-toggle="collapse" href={`${idx}-${childIdx}`}>{child.label} <i className="fas fa-chevron-down"></i></a>
                                                                    <div id={`${idx}-${childIdx}`} className="collapse sideNavPages sideNavPagesInner">
                                                                        <ul className="navbar-nav mt-2">
                                                                            {child.items.map((childItem, childItemIdx) => {
                                                                                return <li key={childItemIdx} className="nav-item">
                                                                                    <a className="nav-link" href={`#${childItem.link}`}>{childItem.label}</a>
                                                                                </li>
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            }
                                                            else {
                                                                return <li key={childIdx} className="nav-item">
                                                                    <a className="nav-link" href={`#${child.link}`}>{child.label}</a>
                                                                </li>
                                                            }
                                                        })}
                                                    </ul>
                                                </div>
                                            </li>
                                        )
                                    }
                                    else {
                                        return <li key={idx} className="nav-item">
                                            <a className="nav-link" href={item.link}>{item.label}</a>
                                        </li>
                                    }
                                })}
                            </ul>
                        </nav>
                    </div>
                </div>
                <div id="close_side_menu" className="tooltip"></div>
                {/* End side menu */}
            </header>
            {/* header */}
            {/*Main Slider*/}
            {/*slider*/}
        </>
    );
}

export default Header;

export const menuItems = [
    { link: "/chair", label: "Chair", footerLink: "/chair/presentation", items: [
        { link: "/chair/presentation", label: "Presentation" },
        { link: "/chair/team", label: "Team", items: [
            { link: "/chair/team/board", label: "Board" },
            { link: "/chair/team/pedagogical-committee", label: "Pedagogical Committee" },
            { link: "/chair/team/scientific-committee", label: "International Scientific Committee" },
            { link: "/chair/team/research-team", label: "Research Team" },
            { link: "/chair/team/advisory-committee", label: "Executive Advisory Committee" }
        ]},
        { link: "/chair/partners", label: "Partners", items: [
            { link: "/chair/partners/universities", label: "Universities" },
            { link: "/chair/partners/public-institutions", label: "Public Institutions" },
            { link: "/chair/partners/unesco", label: "UNESCO Chairs" },
            { link: "/chair/partners/foundations", label: "Foundations and Associations" },
            { link: "/chair/partners/graduate", label: "Graduate Programs" }
        ]},
        { link: "/chair/contacts", label: "Contacts" }
    ]},
    { link: "/research", label: "Research", items: [
        { link: "/research/global-histories", label: "Global Histories" },
        { link: "/research/portugal-game-of-mirrors", label: "Portugal in a Game of Mirrors" },
        { link: "/research/museum-sacred-book", label: "International Museum of the Sacred Book" },
        { link: "/research/global-madeira", label: "Global Madeira" }
    ]},
    { link: "/education", label: "Education and Training", items: [
        { link: "/education/global-studies", label: "Doctoral Program for Global Studies" },
        { link: "/education/entrepreneurship-citizenship", label: "Education for Entrepreneurship and Citizenship" },
        { link: "/education/international-seminars", label: "International Seminars on Global Studies" },
        { link: "/education/lunchtime-seminars", label: "Lunchtime Seminars" },
        { link: "/education/pombaline-studies", label: "Advanced Seminar on Pombaline Studies" }
    ]},
    { link: "/journals", label: "Journals", items: [
        { link: "/journals/global-studies", label: "Journal of Global Studies" },
        { link: "/journals/bulletin", label: "Bulletin" },
        { link: "/journals/revec", label: "REVEC" },
        { link: "/journals/e-lcv", label: "e-LCV" }
    ]},
    { link: "/published", label: "Publications", items: [
        { link: "/published/global-studies", label: "Global Studies Collection" },
        { link: "/published/religo-global", label: "Religo Global Collection" },
        { link: "/published/brazil-collection", label: "Brazil Collection" }
    ]},
    { link: "/news", label: "News" },
    { link: "/archive", label: "Archive", hideInFooter: true,  items: [
        { link: "http://www.cidh-global.org", external: true, label: "CIDH", isExternal: true }
    ]}
];