import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Sections } from "./Journals";
import Banner from "components/Banner/Banner";

function JournalDetail() {
    const { type } = useParams();
    const [ section, setSection ] = useState();

    useEffect(() => {
        setSection(Sections.find(item => item.id.toLowerCase() === type.toLowerCase()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const crumbs = [
        { label: "Home", link :"/" },
        { label: "Journals", link :"/journals" }
    ]

    return (
        <> {section &&
            <>
                <Banner title={section.title} breadcrumbs={crumbs} />
                <section id="our-blog" className="bglight padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-7">
                                <div className="news_item shadow">
                                    {/* {section.image &&
                                        <div className="image">
                                            <img src={section.image} alt={section.imageAlt} className="img-responsive"/>
                                        </div>} */}
                                    <div className="news_desc text-justify">
                                        {/* <h3 className="text-capitalize font-normal darkcolor"><a href="blog-detail.html">Next Large Social Network</a></h3> */}
                                        {section.body}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <aside className="sidebar whitebox mt-5 mt-md-0">
                                    <div className="widget heading_space shadow wow fadeIn" data-wow-delay="350ms">
                                        <h4 className="text-capitalize darkcolor bottom20 text-center text-md-left">Other Journals</h4>
                                        {Sections.filter(item => item.id !== section.id).map((item, idx) => {
                                            return (<div key ={idx} className="single_post bottom15">
                                                <Link to={`/journals/${item.id}`}>
                                                    <i className="fas fa-books"></i>
                                                </Link>
                                                <div className="text">
                                                    <Link to={`/journals/${item.id}`}>{item.title}</Link>
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
            </>}
        </>
    )
}

export default JournalDetail