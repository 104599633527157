import React, { useState, useEffect } from "react";

function Banner(props) {
    const [ imageFile, setImageFile ] = useState();

    useEffect(() => {
        if (props.image) setImageFile(props.image);
        else {
            let number = Math.floor(Math.random() * 10) + 1;
            setImageFile(`bg0${number > 9 ? number : "0"+ number}.jpg`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section id="main-banner-page" className="position-relative page-header parallax section-nav-smooth" style={{ backgroundImage: `url(/images/${imageFile})`}}>
            <div className="overlay overlay-dark opacity-7"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-titles whitecolor text-center">
                            {props.phrases &&
                                <>
                                    {props.phrases[0] && <h2 className="font-xlight">{props.phrases[0]}</h2>}
                                    {props.phrases[1] && <h2 className="font-bold">{props.phrases[1]}</h2>}
                                    {props.phrases[2] && <h2 className="font-xlight">{props.phrases[2]}</h2>}
                                    {props.phrases[3] && <h3 className="font-light pt-2">{props.phrases[3]}</h3>}
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="gradient-bg title-wrap">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 whitecolor">
                            <h3 className="float-left">{props.title}</h3>
                            {props.breadcrumbs &&
                                <ul className="breadcrumb top10 bottom10 float-right">
                                    {props.breadcrumbs.map((crumb, idx) => {
                                        return (<li key={idx} className="breadcrumb-item hover-light">
                                                {crumb.link ? <a href={`#${crumb.link}`} className="nav-link">{crumb.label}</a> : crumb.label}
                                            </li>
                                        )
                                    })}
                                </ul>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner;