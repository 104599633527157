import React from "react";
import { withRouter } from "react-router";
import AppRoutes from "./AppRoutes.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

const App = () => {
    return (
        <>
            <Header />
            {AppRoutes}
            <Footer />
        </>
    );
}

export default withRouter(App);