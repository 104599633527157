import React from "react";
import { Link } from "react-router-dom";
import { Sections as Courses } from "../Education/Education";
import { Sections as Journals } from "../Journals/Journals";
import { Sections as Publications } from "../Published/Published";

function Home() {
    return (
        <>
            <section id="home" class="p-0  single-slide">
                <h2 class="d-none">hidden</h2>
                {/* particles.js container */}
                <div id="particles-js"></div>
                <div class="part-header parallax parallax-slow"></div>
                <div class="overlay overlay-dark opacity-8 z-index-n1"></div>
                {/* particles.js container */}
                <div id="particles-text-morph">
                    <h2 class="font-xlight">Creating critical knowledge to intervene in the construction of an</h2>
                    <h2 id="morph-text" class="pb-2">ecologically, socially, economically</h2>
                    <h3 class="font-light2">sustainable global society</h3>
                </div>
                {/* scroll down */}
                <a href="#our-feature" class="scroll-down pagescroll hover-default">Scroll Down <i class="fas fa-long-arrow-alt-down"></i></a>
            </section>
            <section id="our-feature" className="single-feature padding">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 col-md-7 col-sm-7 text-sm-left text-center wow fadeInLeft" data-wow-delay="300ms">
                            <div className="heading-title mb-4">
                                <h2 className="darkcolor font-normal bottom30">Get to <span className="defaultcolor">Know Us</span> better</h2>
                            </div>
                            <p className="bottom35">Based in the relation between research and teaching, the Chair of Global Studies aims at the understanding the processes and dynamics of globalization, emphasizing approaches on the role of local, social and cultural diversities, namely those that are usually forgotten.</p>
                            <Link to="/chair/presentation" className="button btnsecondary gradient-btn mb-sm-0 mb-4">Read More</Link>
                        </div>
                        <div className="col-lg-5 offset-lg-1 col-md-5 col-sm-5 wow fadeInRight" data-wow-delay="300ms">
                            <div className="image" style={{ backgroundColor: "white"}}><img alt="Chair UNESCO-Aberta" src="/images/logoUnescoAberta-color.png" /></div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="our-process" class="padding bgdark">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 text-center">
                            <div class="heading-title whitecolor wow fadeInUp" data-wow-delay="300ms">
                                <h2 class="font-normal">What we aim for</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <ul class="process-wrapp">
                            <li class="whitecolor wow fadeIn" data-wow-delay="300ms">
                                <span class="pro-step bottom20">01</span>
                                <p class="fontbold bottom25">Analysis</p>
                                <p class="mt-n2 mt-sm-0">Analyse the phenomenon of globalization, concerning subjects related to political and social reality, intercultural communication, education towards globalization, environment, as well as the social, economic and language diversity of societies.</p>
                            </li>
                            <li class="whitecolor wow fadeIn" data-wow-delay="400ms">
                                <span class="pro-step bottom20">02</span>
                                <p class="fontbold bottom25">Research</p>
                                <p class="mt-n2 mt-sm-0">Critically reflect on global issues from an interdisciplinary and multidisciplinary perspective, specially the role of societies and people in this process.</p>
                            </li>
                            <li class="whitecolor wow fadeIn" data-wow-delay="500ms">
                                <span class="pro-step bottom20">03</span>
                                <p class="fontbold bottom25">Dissemination</p>
                                <p class="mt-n2 mt-sm-0">Identify research needs by participating in research teams in Global Studies and disseminating research conducted with peers and the community in general.</p>
                            </li>
                            <li class="whitecolor wow fadeIn" data-wow-delay="600ms">
                                <span class="pro-step bottom20">04</span>
                                <p class="fontbold bottom25">New methodologies</p>
                                <p class="mt-n2 mt-sm-0">Implement appropriate research methodologies and actions to solve global problems.</p>
                            </li>
                            <li class="whitecolor wow fadeIn" data-wow-delay="700ms">
                                <span class="pro-step bottom20">05</span>
                                <p class="fontbold bottom25">Interdisciplinarity</p>
                                <p class="mt-n2 mt-sm-0">Develop interdisciplinary concepts, perspectives and discourses about global identity processes about culture, society, economy and environment.</p>
                            </li>
                            <li class="whitecolor wow fadeIn" data-wow-delay="700ms">
                                <span class="pro-step bottom20">06</span>
                                <p class="fontbold bottom25">Innovation</p>
                                <p class="mt-n2 mt-sm-0">Innovate towards the improvement of living conditions in the sustainable relation between culture, economy, society and the environment, from a global perspective.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id="our-testimonial" class="bglight padding_bottom">
                <div class="parallax page-header testimonial-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 offset-lg-6 col-md-12 text-center text-lg-right">
                                <div class="heading-title wow fadeInUp padding_testi" data-wow-delay="300ms">
                                    <h2 class="whitecolor font-normal">Our (in)formal education offer</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="owl-carousel" id="testimonial-slider">
                        {Courses.map((course, idx) => {
                            return (
                                <div key={idx} class="item testi-box">
                                    <div class="row align-items-center">
                                        <div class="col-lg-4 col-md-12 text-center">
                                            <div class="testimonial-round d-inline-block">
                                                {course.image ? 
                                                    <img src={`/images/logos/${course.image}_300x300.png`} alt=""/>
                                                : 
                                                    <img src="images/logos/testimonial-1.jpg" alt=""/>
                                                }
                                            </div>
                                            <h4 class="defaultcolor font-light top15"><Link to={`/education/${course.id}`}>{course.title}</Link></h4>
                                        </div>
                                        <div class="col-lg-6 offset-lg-2 col-md-10 offset-md-1 text-lg-left text-center">
                                            <p class="bottom15 top90 pt-3">{course.home}</p>
                                            <Link to={`/education/${course.id}`} className="button btnsecondary gradient-btn mb-sm-0 mb-4">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>


            <section id="our-partners">
                <div class="container">
                    <div class="row">
                        <h2 class="d-none">Revistas/Publicações</h2>
                        <div class="col-md-12 col-sm-12">
                            <div id="partners-slider" class="owl-carousel">
                                {Journals.filter(journal => journal.image).map((journal, idx) => {
                                    return (
                                        <div key={idx} class="item">
                                            <div class="logo-item"><Link to={`/journals/${journal.id}`}><img alt={journal.title} src={`/images/logos/${journal.image}_300x300.png`} /></Link></div>
                                        </div>
                                    )
                                })}
                                {Publications.filter(item => item.image).map((item, idx) => {
                                    return (
                                        <div key={idx} class="item">
                                            <div class="logo-item"><Link to={`/journals/${item.id}`}><img alt={item.title} src={`/images/logos/${item.image}_300x300.png`} /></Link></div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;