import React from "react";
import Banner from "components/Banner/Banner";
import { Link } from "react-router-dom";

function Research() {
    const crumbs = [
        { label: "Home", link :"/" }
    ]

    return (
        <>
            <Banner title="Research" breadcrumbs={crumbs} />
            <section id="our-services" className="padding bglight">
                <div className="container">
                    <div id="services-measonry" className="cbp">
                        {Sections.map((section, idx) => {
                            return (
                                <div key={idx} className="cbp-item digital brand design">
                                    <div className="services-main">
                                        <div className="image bottom10">
                                            <div className="image">
                                                {section.image ?
                                                    <img alt={section.imageAlt} src={`/images/logos/${section.image}_560x320.png`}/>
                                                :
                                                    <div className="no-image">
                                                        <i className="fas fa-graduation-cap"></i>
                                                    </div>
                                                }
                                            </div>
                                            <div className="overlay">
                                                <Link to={`/research/${section.id}`} className="overlay_center border_radius"><i className="fa fa-eye"></i></Link>
                                            </div>
                                        </div>
                                        <div className="services-content brand text-center text-md-left">
                                            <h3 className="bottom10 darkcolor"><Link to={`/research/${section.id}`}>{section.title}</Link></h3>
                                            {section.description && <p className="bottom15">{section.description}</p>}
                                            <Link to={`/research/${section.id}`} className="button-readmore">Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Research;

export const Sections = [
    { 
        id: "global-histories",
        title: "Global Histories",
        description: "This project intends to provide critical knowledge through an exercise of analytical synthesis that would allow an overview of the history that, derived from Portuguese territory, or having taken place in this territory, influenced the process of consensus and differences of the current global world. This broad research project includes the Global History of Portuguese Literature, the Global Encyclopedia of Portugal, the Global History of Portugal.",
    },
    { 
        id: "portugal-game-of-mirrors",
        title: "Portugal in a Game of Mirrors",
        description: "The map of our affections and disaffections"
    },
    { 
        id: "museum-sacred-book",
        title: "International Museum of the Sacred Book"    },
    { 
        id: "global-madeira",
        title: "Global Madeira"
    }
]