import React from "react";
import Banner from "components/Banner/Banner";
import { Link } from "react-router-dom";

function News() {
    const crumbs = [
        { label: "Home", link :"/" }
    ]

    return (
        <>
            <Banner title="News" breadcrumbs={crumbs} />
            <section id="our-blog" className="bglight padding">
                <div className="container">
                    {news.length === 0 ?
                        <span className="no-news">Nothing to tell about.. for now!</span>
                    :
                        <>
                            <div id="blog-measonry" className="cbp">
                                {sortItemsByDate().map((item, idx) => {
                                    return (
                                        <div className="cbp-item">
                                            <div className="news_item shadow text-center text-md-left">
                                                {item.images && item.images.length > 0 &&
                                                    <Link to={`/news/${item.id}`} className="image">
                                                        <img src={`/images/news/${item.images[0]}`} alt="Latest News" className="img-responsive" />
                                                    </Link>}
                                                <div className="news_desc">
                                                    <h3 className="text-capitalize font-normal darkcolor"><Link to={`/news/${item.id}`}>{item.title}</Link></h3>
                                                    <ul className="meta-tags top20 bottom20">
                                                        <li><a href="#."><i className="fas fa-calendar-alt"></i>{item.date.toDateString()}</a></li>
                                                    </ul>
                                                    <p className="bottom35">{item.subtitle}</p>
                                                    <Link to={`/news/${item.id}`} className="button gradient-btn">Read more</Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            {/* <div className="row">
                                <div className="col-sm-12">
                                    <ul className="pagination justify-content-center top40">
                                        <li className="page-item"><a className="page-link disabled" href="#."><i className="fa fa-angle-left"></i></a></li>
                                        <li className="page-item active"><a className="page-link" href="#.">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#.">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#.">3</a></li>
                                        <li className="page-item"><a className="page-link" href="#."><i className="fa fa-angle-right"></i></a></li>
                                    </ul>
                                </div>
                            </div> */}
                        </>
                    }
                </div>
            </section>
        </>
    );
}

export default News

export function sortItemsByDate()  {
    return news.sort(function (a, b) {
        return b.date - a.date;
    });
}

export function sortItems()  {
    return news.sort(function (a, b) {
        if (a.id > b.id) {
            return -1;
        }
        if (a.id < b.id) {
            return 1;
        }
        // a must be equal to b
        return 0;
    });
}

export const news = [
    {
        id: 1,
        title: "Sessão CCLXXI dos Seminários à Hora do Almoço",
        subtitle: "Apresentação da obra NORMALIDADE, de Carlos Leone",
        date: new Date("2020-12-20"),
        body: <>
            <p>Exmos. Senhores,</p>
            <p>Vimos, por este meio, convidar-vos para a sessão CCLXXI dos Seminários à Hora do Almoço (Nova Série Letras/Direito), que terá lugar no Salão Nobre da Universidade Aberta (Palácio Ceia, Rua da Escola Politécnica, Lisboa), no dia 10 de dezembro de 2020, às 13h00.</p>
            <p>Nesta sessão, será apresentada a obra Normalidade: seis apontamentos sobre uma integridade maltratada, de Carlos Leone (CLEPUL, Faculdade de Letras da Universidade de Lisboa). Além do autor, intervirão Nuno Garoupa (George Mason University) e Teresa Ruel (ISCSP - Universidade de Lisboa).</p>
            <p>A inscrição para assistir a esta sessão é obrigatória (através do endereço <a href="mailto:circulodecipiao@gmail.com">circulodecipiao@gmail.com</a>), devendo os interessados informar se pretendem participar presencialmente (sujeito a um número limite) ou através do serviço Zoom.</p>
            <p>Contamos com a vossa participação e agradecemos ampla divulgação deste evento.</p>
        </>,
        images: ["20201210_horadealmoco.jpg"]
    },
    {
        id: 2,
        title: "CALL FOR PAPERS",
        subtitle: "CONGRESSO «MULHER, MÃE E RAINHA. 375 ANOS DA COROAÇÃO DE NOSSA SENHORA DA CONCEIÇÃO COMO PADROEIRA DE PORTUGAL»",
        date: new Date("2021-03-25"),
        body: <>
            <p>Exmos. Senhores,</p>
            <p>Nos dias 25, 26 e 27 de março de 2021, decorrerá em Vila Viçosa o Congresso «Mulher, Mãe e Rainha. Nos 375 anos da Coroação de Nossa Senhora da Conceição como Padroeira de Portugal», organizado pelo Instituto da Padroeira de Portugal para os Estudos da Mariologia em parceria com a Cátedra CIPSH de Estudos Globais da Universidade Aberta, entre outras instituições.</p>
            <p>Está aberto, até ao dia 20 de dezembro, o período de envio de propostas de comunicação (<a href="mailto:congressopadroeira.ippem@gmail.com">congressopadroeira.ippem@gmail.com</a>).</p>
            <p>Este congresso «pretende ser um fórum de estudo abrangente, nas temáticas, nas visões e nas abordagens, esperando-se os diferentes contributos relativos aos estudos da Mariologia, da Teologia e da Bíblia; da Religiosidade Popular, das Associações de Fiéis e das Ordens Religiosas (impulsionadoras, em tantos casos, da devoção à Virgem Maria); do Direito Canónico; da Antropologia, da Arte, da História da Igreja; da História de Portugal e até da História Universal» (Sinopse do Congresso).</p>
            <p>Enviamos em anexo informação mais pormenorizada sobre esta iniciativa e agradecemos a sua divulgação.</p>
            <p>Para mais informações: <a href="mailto:congressopadroeira.ippem@gmail.com">congressopadroeira.ippem@gmail.com</a>; <a href="https://padroeiracongresso.wixsite.com/padroeira">https://padroeiracongresso.wixsite.com/padroeira</a>.</p>
        </>,
        images: ["mulhermaerainha.jpg"],
        attachment: "CongressoPadroeira_programa.pdf"
    },
    {
        id: 3,
        title: "World Logic Day",
        subtitle: "The CIPSH Chair for Global Studies decided to associate itself UNESCO’s decision to proclaim January 14 as World Logic Day",
        date: new Date("2021-01-14"),
        body: <>
            <p>«Logical thinking is crucial for building united human communities, because they dare to know and relate to each other on a solid and universal grounding. But this is only possible if there is a capacity for mutual understanding, which implies a permanent effort for dialogue, towards understanding and overcoming possible reasons for division, and participation in common initiatives of different types and scopes (for example, in the preparation of a universal declaration of rights or in a scientific and technological project for the development of a medicine).</p>
            <p>The CIPSH Chair for Global Studies is committed to promoting knowledge of historical, political, scientific and cultural relations between societies and peoples around the world from an open, inclusive and humanistic perspective, through the development of a critical capacity to understand the lights and shadows of these relations over time, identifying common problems and preparing for the future together. Not only Exact Sciences and Philosophy share a concern for the production of rational thought, the whole Human Sciences are also dedicated to it, at the service of Humanity.</p>
            <p>Regarding this subject, there is still a long way to go.</p>
            <p>It is important to strengthen the collaboration of the scientific community worldwide. We mean the establishment of interdisciplinary research groups, with people from all over the world, covering all kinds of problems and issues. At a time when producing science means having power, it is convenient that this power is placed at the service of all peoples, as even now can be seen with the much needed production of vaccines to combat the global pandemic of COVID-19. But there are other problems plaguing Humanity, such as, for example, poverty or child labor, which call for the strengthening of combined efforts.</p>
            <p>The truth is that there is no lack of reasons for hope. In fact, hope is a rational idea. The rationality of hope goes beyond the merely logical, given that it includes the improbable, and it is precisely on this basis that it has been present for centuries in the History of Philosophy and Political Thought as a rational element.</p>
            <p>Hope is a human desire rooted in a possibility that brings together what is most profoundly human. The very motivation for writing this letter is a sign of hope in CIPSH’s remarkable work to promote knowledge on a global scale.»</p>
            <p>(João Relvão Caetano and José Eduardo Franco, Letter of Support)</p>
        </>,
        images: ["world_logic_day1.jpg"]
    },
    {
        id: 4,
        title: "Sessão XVIII do Seminário Internacional de Estudos Pombalinos",
        subtitle: "«O pombalismo, o neopombalismo e o ocaso do Ancien Régime em Portugal (1755-1807)»",
        date: new Date("2021-01-27"),
        body: <>
            <p>Exmos. Senhores,</p>
            <p>Vimos, por este meio, convidar-vos para a Sessão XVIII do Seminário Internacional de Estudos Pombalinos, a realizar-se através da plataforma Zoom, no dia 27 de janeiro (quarta-feira), às 16h00.</p>
            <p>Para esta sessão, contaremos com a presença do Professor Doutor José Subtil (Professor Catedrático da Universidade Autónoma de Lisboa), que apresentará o tema «O pombalismo, o neopombalismo e o ocaso do Ancien Régime em Portugal (1755-1807)».</p>
            <p>Link do Zoom:  <a href="https://videoconf-colibri.zoom.us/j/88180009392">https://videoconf-colibri.zoom.us/j/88180009392</a></p>
        </>,
        images: ["SIEP-XVIII.jpg"]
    },
    {
        id: 5,
        title: "Sessão XIX do Seminário Internacional de Estudos Pombalinos",
        subtitle: "«Reforma Pombalina dos Estudos e Constituição da Sociedade Moderna»",
        date: new Date("2021-03-10"),
        body: <>
            <p>Exmos. Senhores,</p>
            <p>É com muito gosto que vos convidamos a assistir à conferência do Professor Doutor Justino Magalhães (Professor Catedrático do Instituto de Educação da Universidade de Lisboa) sobre «Reforma Pombalina dos Estudos e Constituição da Sociedade Moderna», que será apresentada no âmbito do Seminário Internacional de Estudos Pombalinos, no dia 10 de março, às 18h.</p>
            <p>A sessão realizar-se-á online:  <a href="https://videoconf-colibri.zoom.us/j/84336199619">https://videoconf-colibri.zoom.us/j/84336199619</a></p>
        </>,
        images: ["SIEP-XIX.jpg"]
    },
    {
        id: 6,
        title: "CALL FOR PAPERS",
        subtitle: "Simpósio «'Glocalizar' a Dignidade: Da Construção Histórica dos Direitos Humanos à Consciencialização Prática em Contexto de Globalização»",
        date: new Date("2021-03-31"),
        body: <>
            <p>Exmos. Senhores,</p>
            <p>Encontra-se aberto, <strong>até ao próximo dia 31 de março</strong>, o período para submissão de propostas aos simpósios do I Congresso de Direito Internacional, promovido pela NOVA School of Law, a realizar nos dias 19 e 20 de julho de 2021.</p>
            <p>Convidamos, em especial, para a submissão de propostas ao simpósio subordinado ao tema <strong>"Glocalizar" a Dignidade: Da Construção Histórica dos Direitos Humanos à Consciencialização Prática em Contexto de Globalização (S098)</strong>, cuja apresentação pode ser consultada <a href="https://milleniumcdi.pt/wp-content/uploads/2021/02/ETPT05S098.pdf">aqui</a>.</p>
            <p>Para o formulário de submissão de resumos: <a href="https://milleniumcdi.pt/simposios-eixo-05/">https://milleniumcdi.pt/simposios-eixo-05/</a></p>
            <p>Toda a informação sobre o processo de submissão de resumos poderá ser consultada em <a href="https://milleniumcdi.pt/chamada-para-comunicacoes/">https://milleniumcdi.pt/chamada-para-comunicacoes/</a>.</p>
        </>,
        images: ["cogresso-direito-internacional.png"]
    },
    {
        id: 7,
        title: "Chamada de Artigos",
        subtitle: "«Globalização e Pandemia. Risco e Incerteza», da revista e-Letras com Vida",
        date: new Date("2021-02-22"),
        body: <>
            <p>Exmos. Senhores,</p>
            <p>Divulgamos a chamada de artigos para o dossiê temático "Globalização e Pandemia. Risco e Incerteza", da revista e-Letras com Vida.</p>
        </>,
        images: ["e-letrascv.jpg"],
        attachment: "Chamada de artigos - Globalização e Pandemia.pdf"
    },
    {
        id: 8,
        title: "Chamada de Artigos",
        subtitle: "«Literatura e Globalização», no âmbito do Doutoramento em Estudos Globais",
        date: new Date("2021-02-19"),
        body: <>
            <p>Exmos. Senhores,</p>
            <p>Divulgamos a chamada de artigos para a publicação do dossiê / obra coletiva "Literatura e Globalização", enquadrada nas ações previstas no âmbito do Doutoramento em Estudos Globais.</p>
        </>,
        images: ["e-letrascv.jpg"],
        attachment: "Chamada de artigos - Literatura e Globalização.pdf"
    },
    {
        id: 9,
        title: "CONVITE | XIII SEMINÁRIO INTERNACIONAL DE ESTUDOS GLOBAIS | ZOOM",
        subtitle: "ORADOR: JOSÉ MARCELO FREITAS DE LUNA (UNIVALI)",
        date: new Date("2021-02-19"),
        body: <>
            <p>Exmos. Senhores,</p>
            <p>É com muito gosto que vos convidamos para a XIII Sessão do <strong>Seminário Internacional de Estudos Globais</strong> (web seminar), que decorrerá no dia <strong>19 de fevereiro de 2021, às 18h00.</strong></p>
            <p>Será orador convidado o <strong>Prof. Doutor José Marcelo Freitas de Luna</strong> (Investigador Sénior do Programa de Doutoramento da Univali, Brasil), que apresentará a conferência <strong>"Internacionalização e impactos na produção das políticas curriculares para as Humanidades"</strong>. O comentário crítico estará a cargo da <strong>Prof. Doutora Darlinda Moreira</strong> (Pró-Reitora para a Inovação e Gestão Pedagógica da Universidade Aberta).</p>
            <p>Informações: <a href="mailto:catedraestudosglobais.ceg.uab@gmail.com">catedraestudosglobais.ceg.uab@gmail.com</a></p>
            <p>Link do Zoom:  <a href="https://videoconf-colibri.zoom.us/j/82646985495">https://videoconf-colibri.zoom.us/j/82646985495</a></p>
        </>,
        images: ["SIEG-XIII.jpg"]
    },
    {
        id: 10,
        title: "CONVITE | XII SEMINÁRIO INTERNACIONAL DE ESTUDOS GLOBAIS | ZOOM",
        subtitle: "ORADOR: DELFIM LEÃO | LANÇAMENTO DO LIVRO 'GLOBALIZAÇÃO COMO PROBLEMA', POR PAULO FERREIRA DA CUNHA",
        date: new Date("2021-02-11"),
        body: <>
            <p>Exmos. Senhores,</p>
            <p>É com muito gosto que vos convidamos para a <strong>XII Sessão do Seminário Internacional de Estudos Globais</strong> (web seminar), que decorrerá no dia <strong>11 de fevereiro de 2021, às 16h00</strong>.</p>
            <p>Será orador convidado o <strong>Professor Doutor Delfim Leão</strong> (Vice-Reitor da Universidade de Coimbra e Professor Catedrático de Estudos Clássicos), que apresentará a conferência <strong>"Dinâmicas de Globalização na Antiguidade Clássica: <i>o polites e o kosmopolites</i>"</strong>. O comentário crítico estará a cargo do <strong>Prof. Doutor André Simões</strong> (Investigador do Centro de Estudos Clássicos e docente na Faculdade de Letras da Universidade de Lisboa).</p>
            <p>Nesta sessão, será também lançado o primeiro livro da Coleção de Estudos Globais, intitulado <strong><i>Globalização como Problema</i></strong>, pelo <strong>Professor Doutor Paulo Ferreira da Cunha</strong> (Juiz  do Supremo Tribunal de Justiça e docente na Faculdade de Direito da Universidade do Porto).</p>
            <p>Informações: <a href="mailto:catedraestudosglobais.ceg.uab@gmail.com">catedraestudosglobais.ceg.uab@gmail.com</a></p>
            <p>Link do Zoom:  <a href="https://videoconf-colibri.zoom.us/j/86366365782">https://videoconf-colibri.zoom.us/j/86366365782</a></p>
        </>,
        images: ["SIEG-XII.jpg"]
    },
    {
        id: 11,
        title: "CONVITE | XI SEMINÁRIO INTERNACIONAL DE ESTUDOS GLOBAIS | ZOOM",
        subtitle: "ORADOR: PROFESSOR DOUTOR CARLOS BORREGO",
        date: new Date("2021-01-15"),
        body: <>
            <p>Exmos. Senhores,</p>
            <p>É com muito gosto que vos convidamos para a <strong>XI Sessão do Seminário Internacional de Estudos Globais</strong> (web seminar), que decorrerá no dia <strong>15 de janeiro de 2021, às 18h00</strong>, a partir do <strong>Salão Nobre da Universidade Aberta (Palácio Ceia)</strong>.</p>
            <p>Será orador convidado o <strong>Professor Doutor Carlos Borrego</strong> (Professor Catedrático, Departamento de Ambiente e Ordenamento da Universidade de Aveiro), que apresentará o tema <strong>“Ambiente e globalização: desafio para o desenvolvimento sustentável”</strong>. Fará um comentário crítico a <strong>Prof. Doutora Sandra Caeiro</strong> (Professora Associada com Agregação, Departamento de Ciências e Tecnologias da Universidade Aberta). </p>
            <p>A inscrição para assistir presencialmente a esta sessão é obrigatória (opção sujeita a um número limite de participantes).</p>
            <p>Informações: <a href="mailto:catedraestudosglobais.ceg.uab@gmail.com">catedraestudosglobais.ceg.uab@gmail.com</a></p>
            <p>Link do Zoom:  <a href="https://videoconf-colibri.zoom.us/j/83936134795">https://videoconf-colibri.zoom.us/j/83936134795</a></p>
        </>,
        images: ["SIEG-XI.jpg"],
        attachment: "SIEG-XI_Resumo da conferencia e resenha biografica do orador.pdf"
    },
    {
        id: 12,
        title: "CONFERÊNCIA EUROPEIA DAS HUMANIDADES | ZOOM",
        subtitle: "CONFERÊNCIA TEMÁTICA: PODERÁ A UNIVERSIDADE SUBSISTIR SEM HUMANIDADES?",
        date: new Date("2021-04-29"),
        body: <>
            <p>Exmos. Senhores,</p>
            <p>No âmbito da Conferência Europeia das Humanidades (<a href="http://www.europeanhumanities2021.ipt.pt">http://www.europeanhumanities2021.ipt.pt</a>), a Cátedra CIPSH de Estudos Globais e o Pólo do Clepul da Universidade da Madeira organizaram uma conferência temática subordinada ao tema <strong>“Poderá a Universidade subsistir sem Humanidades? As Humanidades como fundadoras do projeto de Universidade”.</strong></p>
            <p>Esta conferência temática realizar-se-á no dia <strong>29 de abril de 2021</strong>, a partir das <strong>18h</strong> (hora de Lisboa), e contará com as intervenções de <strong>Carlos Fiolhais</strong> (Universidade de Coimbra), <strong>Daniela Marcheschi</strong> (Universidade de Pisa), <strong>Moisés Lemos Martins</strong> (Universidade do Minho), <strong>Onésimo Teotónio Almeida</strong> (Universidade de Brown) e <strong>Renaldas Gudauskas</strong> (Biblioteca Nacional da Lituânia/Conselho Internacional de Filosofia e Ciências Humanas da UNESCO).</p>
            <p>A participação é livre!</p>
            <p>Informações: <a href="mailto:ceg.estudosglobais@uab.pt">ceg.estudosglobais@uab.pt</a></p>
            <p>Link do Zoom:  <a href="https://videoconf-colibri.zoom.us/j/84978914687">https://videoconf-colibri.zoom.us/j/84978914687</a></p>
        </>,
        images: [ "European Humanities Conference.jpg"],
        attachment: "Apresentacao_Presentation.pdf"
    },
    {
        id: 13,
        title: "CONVITE | CONGRESSO - III JORNADAS 'A BÍBLIA DE ALMEIDA'",
        date: new Date("2021-10-29"),
        body: <>
            <p>Exmos. Senhores,</p>
            <p>É com muito gosto que vos convidamos a assistir ao congresso - III Jornadas 'A Bíblia de Almeida', no dia 29 de outubro, às 19h.</p>
            <p>A sessão realizar-se-á online:  <a href="https://us02web.zoom.us/j/86772092178?pwd=cjl6SWtIejh0UWUxZmowVEtHVjVCUT09">https://us02web.zoom.us/j/86772092178?pwd=cjl6SWtIejh0UWUxZmowVEtHVjVCUT09</a></p>
        </>,
        images: [ "congresso_biblia-de-almeida.jpg"],
        attachment: "congresso_biblia-de-almeida.pdf"
    },
    {
        id: 14,
        title: "Sessão XXIV do Seminário Internacional de Estudos Pombalinos",
        subtitle: "«A nomeação de Carvalho e Melo como embaixador em Londres e o recrutamento diplomático do século XVIII»",
        date: new Date("2021-11-11"),
        body: <>
            <p>Exmos. Senhores,</p>
            <p>No dia <strong>11 de novembro, às 18h00</strong>, realiza-se a Sessão XXIV do Seminário Internacional de Estudos Pombalinos, com a apresentação da conferência <strong>"A nomeação de Carvalho e Melo como embaixador em Londres e o recrutamento diplomático do século XVIII"</strong>, apresentada por <strong>Júlia Korobtchenko</strong> (Centro de História da Universidade de Lisboa).</p>
            <p>A sessão decorrerá em formato misto:</p>
            <p>- <strong>Presencial:</strong> Salão Nobre da Universidade Aberta - Palácio Ceia (neste caso, mediante <strong>inscrição obrigatória</strong>) ou<br/>
            - <strong>Zoom:</strong> <a href="https://videoconf-colibri.zoom.us/j/81982122433">https://videoconf-colibri.zoom.us/j/81982122433</a></p>
            <p>Informações e inscrições: <a href="mailto:catedraestudosglobais.ceg.uab@gmail.com">catedraestudosglobais.ceg.uab@gmail.com</a></p>
        </>,
        images: ["SIEP-XIX.jpg"]
    },
    {
        id: 15,
        title: "Abertura do Processo Eleitoral do Centro de Estudos Globais",
        subtitle: "",
        date: new Date("2022-02-23"),
        body: <>
            <p>Exmos. Senhores,</p>
            <p>Nos termos dos estatutos do Centro de Estudos Globais, artigo 7o, referente à eleição da direção, foi dado como aberto o processo eleitoral.</p>
            <p>Toda a informação relativa ao processo eleitoral está disponível no anexo.</p>
        </>,
        attachment: "3CEG processo eleitoral1.pdf"
    }
]