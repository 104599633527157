import React from "react";
import Banner from "components/Banner/Banner";
import { Link } from "react-router-dom";

function Journals() {
    const crumbs = [
        { label: "Home", link :"/" }
    ]

    return (
        <>
            <Banner title="Journals" breadcrumbs={crumbs} />
            <section id="our-services" className="padding bglight">
                <div className="container">
                    <div id="services-measonry" className="cbp-singlePage">
                        {Sections.map((section, idx) => {
                            return (
                                <div className="cbp-item digital brand design">
                                    <div className="services-main">
                                        <div className="image bottom10">
                                            <div className="image">
                                                {section.image ?
                                                    <img alt={section.imageAlt} src={`/images/logos/${section.image}_560x320.png`}/>
                                                :
                                                    <div className="no-image">
                                                        <i className="fas fa-books"></i>
                                                    </div>
                                                }
                                            </div>
                                            <div className="overlay">
                                                <Link to={`/journals/${section.id}`} className="overlay_center border_radius"><i className="fa fa-eye"></i></Link>
                                            </div>
                                        </div>
                                        <div className="services-content brand text-center text-md-left">
                                            <h3 className="bottom10 darkcolor"><Link to={`/journals/${section.id}`}>{section.title}</Link></h3>
                                            {section.description && <p className="bottom15">{section.description}</p>}
                                            <Link to={`/journals/${section.id}`} className="button-readmore">Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Journals

export const Sections = [
    { 
        id: "global-studies",
        title: "Journal of Global Studies",
        image: "revista-estudos-globais",
        body: <>
            <p>The project to create a digital journal of Global Studies within the framework of the Doctoral Program for Global Studies has globalization as its central theme, as well as a problem.</p>
            <p>This journal is situated in the main scientific area of the Doctoral Program for Global Studies, and intends to provide, in close dialogue with different areas of knowledge, an approach, in an academic context and in «real time», in the concrete time of events, of this emerging field. It will also provide an analysis, on the one hand, of the relations of peoples, cultures and civilizations among themselves and, on the other hand, of the way in which peoples, cultures and civilizations produce the experience of globalization that marks contemporary cultural and scientific relations.</p>
            <p>It will be a biannual, inter and transdisciplinary scientific periodical, subject to international quality criteria and scientific arbitration and open to the participation of national and international experts.</p>
        </>
    },
    { 
        id: "bulletin",
        title: "Bulletin",
        body: <>
            <p>The Bulletin of the Chair for Global Studies aims to publicize initiatives carried out and planned under this program. It has a biannual schedule, and it is a fundamental tool for archiving and preserving historical memory.</p>
        </>
    },
    { 
        id: "revec",
        title: "Revec",
        image: "revista-estudos-cultura",
        body: <>
            <p>The UFS Culture Studies journal is a journal of the UFS Culture Studies Center, which is now associated with the Chair of Global Studies. This Center was created with the purpose of bringing together researchers from the large areas of Humanities, Social and Applied Sciences and Letters, Linguistics and Arts. This way, we can remove the limitations of the renewing of thought and reflection on modern and contemporary culture, in a reciprocal and non-hierarchical relationship of exchanges, by focusing on the study of its organizational and representational aspects.</p>
        </>
    },
    { 
        id: "e-lcv",
        title: "E-Letras com Vida",
        image: "e-lcv",
        body: <>
            <p>The E-Letras com Vida - Journal of Humanities and Arts [e-LCV] is a biannual scientific publication by the Centre for Lusophone and European Literatures and Cultures of the Faculty of Letters of the University of Lisbon (CLEPUL), which is associated with the Chair of Global Studies. It aims to disseminate original studies on Literature, especially in its cultural inscription and in dialogue with History, Philosophy, Arts and Sciences.</p>
            <p>The e-LCV includes a thematic dossier, a section of multi-thematic articles, an interview, a section for readings (reviews, short essays) and another for news (on congresses, presentations, projects, academic works).</p>
        </>
    }
]