import React from "react";
import { Switch } from "react-router-dom";
import Route from "components/Route/Route";

import Home from "pages/Home/Home.js";
import Presentation from "pages/Chair/Presentation/Presentation";
import Team from "pages/Chair/Team";
import Partners from "pages/Chair/Partners";
import Contacts from "pages/Chair/Contacts/Contacts";
import Research from "pages/Research/Research";
import ResearchDetail from "pages/Research/ResearchDetail";
import Journals from "pages/Journals/Journals";
import JournalDetail from "pages/Journals/JournalDetail";
import Published from "pages/Published/Published";
import PublishedDetail from "pages/Published/PublishedDetail";
import Education from "pages/Education/Education";
import EducationDetail from "pages/Education/EducationDetail";
import News from "pages/News/News";
import Article from "pages/News/Article";

const AppRoutes = (
	<Switch>
		<Route exact path="/" component={Home} />
		<Route exact path="/chair/presentation" component={Presentation} />
		<Route exact path="/chair/team/:type" component={Team} />
		<Route exact path="/chair/partners/:type" component={Partners} />
		<Route exact path="/chair/contacts" component={Contacts} />
		<Route exact path="/research" component={Research} />
		<Route exact path="/research/:id" component={ResearchDetail} />
		<Route exact path="/journals" component={Journals} />
		<Route exact path="/journals/:type" component={JournalDetail} />
		<Route exact path="/published" component={Published} />
		<Route exact path="/published/:id" component={PublishedDetail} />
		<Route exact path="/education" component={Education} />
		<Route exact path="/education/:type" component={EducationDetail} />
		<Route exact path="/news" component={News} />
		<Route exact path="/news/:id" component={Article} />
		<Route path="http://www.cidh-global.org" component={() => {
			window.location.href = "http://www.cidh-global.org"; return null;
		}} /> 
	</Switch>
);

export default AppRoutes;